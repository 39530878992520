import React, { Component } from 'react'
import CsvDownloader from 'react-csv-downloader'
import ReactLoading from 'react-loading'
import { Redirect } from 'react-router-dom'
import Select from '../../components/Select'
import { DateRangePicker } from 'react-date-range'
import * as rdrLocales from 'react-date-range/dist/locale'
import { InputGroup, Input, InputGroupAddon, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'
import { Badge } from 'reactstrap'
import pdfMake from 'pdfmake'


pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    }
}

class Form extends Component {
    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        detalhesDefault: { data: "", usuario: { label: 'Nenhum', value: null }, status: { label: 'Nenhum', value: null }, filtrar: { label: 'Nenhum', value: null }, monitorado: [], automacao: [] },
        dataFormatada: "",
        datePickerShow: false,
        dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
        optionsCliente: [],
        optionsUsuario: [],
        optionsMonitorado: [],
        optionsAutomacao: [],
        optionsStatus: [
            { label: 'Nenhum', value: null },
            { label: "Novo", value: "new" },
            { label: "Pendente", value: "pending" },
            { label: "Finalizado", value: "finished" },
        ],
        optionsFiltro: [
            { label: 'Nenhum', value: null },
            { label: "Monitorado", value: "monitorado" },
            { label: "Automação", value: "automacao" },
        ],
        carregando: false,
        relatorioGerado: false,
        table: [],
        modalStatus: false,
        keyAlerta: false,
        csv: { data: [], columns: [] },
        mensagemErro: ""
    }

    componentDidMount() {
        //this.getClientes()
        this.getMonitorados()
        this.getAutomacoes()
        this.getUsuarios()

        window.addEventListener('click', this.checarCalendarioAberto, false)
    }

    checarCalendarioAberto = (e) => {
        if (this.state.checkClose === true) {
            let retArray = e.path?.filter((path) => {
                if (path.className === "hdv-datepicker") {
                    return true
                }
                return false
            })

            if (retArray?.length === 0) {
                this.setState({ "datePickerShow": false, checkClose: false })
            }
        } else {
            this.setState({ checkClose: true })
        }

        return true
    }

    getUsuarios = () => {
        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + `/usuario/`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(res => {
                const optionsUsuario = [{ label: 'Nenhum', value: null }]
                optionsUsuario.push({label: this.userInfo.nome, value: this.userInfo.email})

                res.data.results.forEach((usuario) => {
                    optionsUsuario.push({ label: usuario.nome, value: usuario.usuario__username })
                })

                this.setState({ optionsUsuario })
            })
    }

    getMonitorados = () => {
        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + `/lista-monitorado/`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(res => {
                const optionsMonitorado = []

                res.data.results.forEach((monitorado) => {
                    optionsMonitorado.push({ label: monitorado.nome, value: monitorado.id })
                })

                this.setState({ optionsMonitorado })
            })
    }

    getAutomacoes = () => {
        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + `/automacao/`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(res => {
                const optionsAutomacao = []

                res.data.results.forEach((automacao) => {
                    optionsAutomacao.push({ label: automacao.descricao, value: automacao.id })
                })

                this.setState({ optionsAutomacao })
            })
    }

    buscarDadosRelatorio = () => {
        const { detalhesDefault } = this.state
        this.setState({ cooldown: true, carregando: true, relatorioGerado: false, mensagemErro: "" })

        const data = detalhesDefault.data.split("-")
        const bodyFormData = {
            data_inicio: data[0],
            data_fim: data[1],
            usuario: detalhesDefault.usuario?.value ?? "",
            status: detalhesDefault.status?.value ?? ""
        }

        if (detalhesDefault.filtrar.value === "monitorado") {
            bodyFormData['monitorado'] = detalhesDefault.monitorado.map((monitorado) => monitorado.value)
            bodyFormData['automacao'] = []
        } else if (detalhesDefault.filtrar.value === "automacao") {
            bodyFormData['monitorado'] = []
            bodyFormData['automacao'] = detalhesDefault.automacao.map((automacao) => automacao.value)
        } else {
            bodyFormData['monitorado'] = []
            bodyFormData['automacao'] = []
        }

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo?.empresa + '/relatorio/alerta/gerar/',
            data: JSON.stringify(bodyFormData),
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((res) => {
            let csv = { data: [], columns: [] }
            const status = {
                "new": "Novo",
                "pending": "Pendente",
                "finished": "Finalizado"
            }

            csv.columns = [
                { id: 'col1', displayName: '' },
                { id: 'col2', displayName: '' },
                { id: 'col3', displayName: '' },
                { id: 'col4', displayName: '' },
                { id: 'col5', displayName: '' }
            ]

            res.data.forEach((alerta) => {
                csv.data.push({ col1: 'Descrição', col2: '', col3: 'Data', col4: 'Status', col5: 'Automação' })
                csv.data.push({ col1: alerta.descricao, col2: '', col3: alerta.data_hora_alerta, col4: status[alerta.status], col5: alerta.reporte.data.descricao })

                alerta.interacoes.forEach((interacao, index_interacao) => {
                    const date = new Date(interacao.date * 1000);
                    const dateTime = date.toLocaleDateString() + " " + date.toLocaleTimeString()

                    if (index_interacao === 0) {
                        csv.data.push({ col1: '', col2: 'Interações', col3: '', col4: '', col5: '' })
                        csv.data.push({ col1: '', col2: 'Usuário', col3: 'Data', col4: 'Status', col5: 'Conteúdo' })
                    }

                    csv.data.push({ col1: '', col2: interacao.user, col3: dateTime, col4: status[interacao.status], col5: interacao.content })
                })
            })

            this.setState({ table: res.data, relatorioGerado: true, csv })
        }).catch((error) => {
            this.setState({ mensagemErro: error.response.data })
        })

        setTimeout(() => this.setState({ cooldown: false, carregando: false }), 2000)
    }


    handleChange = (key, value) => {
        let { detalhesDefault } = this.state
        detalhesDefault[key] = value

        this.setState({ detalhesDefault })
    }

    getClientes = () => {
        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/cliente/', this.configMe).then(cliente => {
            let optionsCliente = []

            optionsCliente.push({ label: 'Nenhum', value: null })

            if (cliente.data?.results.length > 0) {
                cliente.data?.results?.forEach((c) => {
                    optionsCliente.push({ "value": c.id, "label": c.nome_fantasia })
                })
            }

            this.setState({ optionsCliente })
        })
    }


    handleSelect = (ranges) => {
        const { detalhesDefault } = this.state
        const startDate = ranges.selection.startDate
        const endDate = ranges.selection.endDate

        detalhesDefault.data = startDate.toLocaleDateString() + "-" + endDate.toLocaleDateString()
        this.setState({
            dateRange: {
                startDate: startDate,
                endDate: endDate,
                key: 'selection',
            },
            dataFormatada: startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString(),
            detalhesDefault: detalhesDefault,
        }, this.validatePeriod)
    }

    showDatePicker = () => {
        this.setState({ datePickerShow: !this.state.datePickerShow })
    }

    getBase64ImageFromURL = (url) => {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.setAttribute("crossOrigin", "anonymous")

            img.onload = () => {
                let canvas = document.createElement("canvas")
                canvas.width = img.width
                canvas.height = img.height

                let ctx = canvas.getContext("2d")
                ctx.drawImage(img, 0, 0)

                let dataURL = canvas.toDataURL("image/png")

                resolve(dataURL)
            }

            img.onerror = error => reject(error)

            img.src = url
        })
    }

    exportarPDF = async () => {
        const { detalhesDefault } = this.state
        let logo = true

        let infoEmpresa
        await axios.get(cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/configuracao-sistema`, this.configMe).then(res => { infoEmpresa = res.data?.results[0] })

        let prepare_data = []

        const status = {
            "new": "Novo",
            "pending": "Pendente",
            "finished": "Finalizado"
        }

        this.state.table.forEach((alerta, index_alerta) => {

            prepare_data.push([{ text: "Descrição", colSpan: 2, fillColor: "#b7b7b7" }, {}, { text: "Data", fillColor: "#b7b7b7" }, { text: "Status", fillColor: "#b7b7b7" }, { text: "Automação", fillColor: "#b7b7b7" }])

            prepare_data.push([{ text: alerta.descricao, colSpan: 2 }, {}, { text: alerta.data_hora_alerta }, { text: status[alerta.status] }, { text: alerta.reporte.data.descricao }])

            alerta.interacoes.forEach((interacao, index_interacao) => {
                const date = new Date(interacao.date * 1000);
                const dateTime = date.toLocaleDateString() + " " + date.toLocaleTimeString()

                if (index_interacao === 0) {
                    prepare_data.push([{ text: "", border: [true, false, true, false] }, { text: "Interações", colSpan: 4, fillColor: "#b7b7b7" }, {}, {}, {}])
                    prepare_data.push([{ text: "", border: [true, false, true, false] }, { text: 'Usuário', fillColor: '#cccccc' }, { text: 'Data', fillColor: '#cccccc' }, { text: 'Status', fillColor: '#cccccc' }, { text: 'Conteúdo', fillColor: '#cccccc' }])
                }

                let ultimo = index_interacao === (alerta.interacoes.length - 1)

                prepare_data.push([{ text: "", border: ultimo ? [true, false, true, true] : [true, false, true, false] }, { text: interacao.user }, { text: dateTime }, { text: status[interacao.status] }, { text: interacao.content }])
            })
        })


        let header = []
        let content = [
            {
                columns: [
                    {
                        width: '*',
                        text: 'Relatório de Alertas',
                        margin: [0, 50, 0, 0],
                        fontSize: 18,
                        bold: true,
                    },
                    {
                        width: 360,
                        margin: [0, 0, 10, 0],
                        alignment: 'right',
                        text: [infoEmpresa?.email_contato, '\n', infoEmpresa?.endereco, '\n', infoEmpresa?.telefone, "\n"]
                    },
                    (logo) ? {
                        width: 100,
                        heigth: 100,
                        image: await this.getBase64ImageFromURL(document.querySelector('div.hdv-logomarca img').src),
                    } : null,
                ]
            }, {
                text: ".",
                color: "white"
            }, {
                table: {
                    headerRows: 1,
                    widths: ["*"],
                    body: [
                        ["Relatório de Alertas " + " - Período: " + detalhesDefault.data?.replace('-', ' à ')],
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 2 : 1
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 2 : 1
                    },
                    hLineColor: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 'black' : 'gray'
                    },
                    vLineColor: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 'white' : 'white'
                    },
                }
            }, {
                style: 'tableConsumo',
                color: '#444',
                fontSize: 10,
                table: {
                    widths: ["*", "*", "*", "*", "*",],
                    headerRows: 1,
                    body: prepare_data
                }
            },
        ]
        let footer = []

        let fileDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [10, 10, 10, 20],
            header: [header],
            content: [content],
            styles: { tableConsumo: { margin: [0, 20, 0, 0] }, },
            footer: [footer],
        }

        pdfMake.createPdf(fileDefinition).open()

    }

    render() {
        const { detalhesDefault, relatorioGerado, modalStatus, keyAlerta, csv, cooldown, mensagemErro } = this.state

        if (this.state.redirect)
            return <Redirect to="/relatorio-alertas" />

        return (
            <>
                <Modal className="modal-large" isOpen={modalStatus} toggle={() => this.setState({ modalStatus: false, keyAlerta: false })}>
                    <ModalHeader toggle={() => this.setState({ modalStatus: false, keyAlerta: false })}>Interações</ModalHeader>
                    <ModalBody>
                        <table className='afira-table'>
                            <thead>
                                <tr>
                                    <th>Usuário</th>
                                    <th>Data</th>
                                    <th>Status</th>
                                    <th>Conteúdo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.table[keyAlerta] ?
                                    this.state.table[keyAlerta].interacoes.map((interacao, index) => {
                                        const status = {
                                            "new": <Badge color="primary">Novo</Badge>,
                                            "pending": <Badge color="warning">Pendente</Badge>,
                                            "finished": <Badge color="success">Finalizado</Badge>
                                        }
                                        const date = new Date(interacao.date * 1000);
                                        const dateTime = date.toLocaleDateString() + " " + date.toLocaleTimeString()

                                        return (
                                            <tr key={index}>
                                                <td title={interacao.user}>{interacao.user}</td>
                                                <td title={dateTime}>{dateTime}</td>
                                                <td>{status[interacao.status]}</td>
                                                <td title={interacao.content}>{interacao.content}</td>
                                            </tr>
                                        )
                                    })
                                    : null
                                }
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={() => this.setState({ modalStatus: false, keyAlerta: false })} className='hdv-btn-forms hdv-btn-red'>Fechar</button>
                    </ModalFooter>
                </Modal>

                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Relatório de Alertas</h4>
                        <div className="gray-background">
                            <i className="fa fa-history fa-2x"></i>
                        </div>
                    </span>
                    {this.state.carregando ?
                        <ReactLoading className="hdv-report-loading" type="cylon" color="#589bd4" width={40} />
                        :
                        null
                    }
                </div>

                <details open={true} className="custom-summary">
                    <summary>Filtros</summary>
                    <form style={{ padding: "20px" }} className="hdv-form-default">
                        <div className='row'>
                            <div className="col-md-4">
                                <label className="required mt-0">Usuário</label>
                                <Select
                                    value={detalhesDefault.usuario}
                                    onChange={(e) => this.handleChange('usuario', e)}
                                    options={this.state.optionsUsuario}
                                />
                            </div>
                            <div className="col-md-4">
                                <label className="required mt-0">Status</label>
                                <Select
                                    value={detalhesDefault.status}
                                    onChange={(e) => this.handleChange('status', e)}
                                    options={this.state.optionsStatus}
                                />
                            </div>
                            <div className="col-md-4">
                                <label className="required mt-0">Filtrar por</label>
                                <Select
                                    value={detalhesDefault.filtrar}
                                    onChange={(e) => this.handleChange('filtrar', e)}
                                    options={this.state.optionsFiltro}
                                />
                            </div>
                        </div>
                        <div className='row' style={{ marginTop: "15px" }}>
                            {
                                detalhesDefault.filtrar.value === "monitorado" ?
                                    <div className="col-md-12 ">
                                        <label className="required mt-0">Monitorados</label>
                                        <Select
                                            forceHeightAuto
                                            value={detalhesDefault.monitorado}
                                            onChange={(e) => this.handleChange('monitorado', e)}
                                            options={this.state.optionsMonitorado}
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                        />
                                    </div>
                                    : detalhesDefault.filtrar.value === "automacao" ?
                                        <div className="col-md-12">
                                            <label className="required mt-0">Automações</label>
                                            <Select
                                                forceHeightAuto
                                                value={detalhesDefault.automacao}
                                                onChange={(e) => this.handleChange('automacao', e)}
                                                options={this.state.optionsAutomacao}
                                                isMulti={true}
                                                closeMenuOnSelect={false}
                                            />
                                        </div>
                                        : null
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <label className="required mt-0">Periodo</label>
                                <InputGroup className="hdv-force-hight">
                                    <Input className="hdv-force-hight" placeholder="Selecione o periodo" onChange={() => false} disabled value={this.state.dataFormatada} />
                                    <InputGroupAddon addonType="append">
                                        <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"} >
                                            <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                                            {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                                <div className={(this.state.datePickerShow) ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                                    <DateRangePicker locale={rdrLocales.pt} ranges={[this.state.dateRange]} onChange={this.handleSelect} />
                                </div>
                            </div>
                        </div>
                    </form>
                </details>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="hdv-btn-group">
                        <button
                            disabled={!detalhesDefault.data || cooldown}
                            className="hdv-btn-forms hdv-btn-blue"
                            onClick={this.buscarDadosRelatorio}>
                            Gerar Relatório
                        </button>

                        <CsvDownloader
                            style={{ border: 'none' }}
                            columns={csv.columns}
                            datas={csv.data}
                            filename={"relatorio_alertas.csv"}
                            separator=";">
                            <button disabled={!this.state.relatorioGerado} className="hdv-btn-forms hdv-btn-green ">Exportar CSV</button>
                        </CsvDownloader>

                        <button
                            disabled={!this.state.relatorioGerado}
                            className="hdv-btn-forms hdv-btn-red"
                            onClick={this.exportarPDF}>
                            <i className="fa fa-file-pdf-o fa-20px"></i> Exportar PDF
                        </button>
                    </div>
                </div>

                {relatorioGerado ?
                    <section id="hdv-multi-report-print" className="mb-4">
                        <table className="table afira-table">
                            <thead>
                                <th>Descrição</th>
                                <th>Data</th>
                                <th>Status</th>
                                <th>Interações</th>
                                <th>Automação</th>
                            </thead>
                            <tbody>
                                {
                                    this.state.table.map((item, key) => {
                                        const status = {
                                            "new": <Badge color="primary">Novo</Badge>,
                                            "pending": <Badge color="warning">Pendente</Badge>,
                                            "finished": <Badge color="success">Finalizado</Badge>
                                        }

                                        return (
                                            <tr key={key}>
                                                <td>{item.descricao}</td>
                                                <td>{item.data_hora_alerta}</td>
                                                <td>{status[item.status]}</td>
                                                <td onClick={() => this.setState({ modalStatus: true, keyAlerta: key })} style={{ cursor: "pointer", color: "#007bff" }}>Visualizar Interações</td>
                                                <td>{item.reporte.data.descricao}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </section>
                    : mensagemErro ? <p style={{ textAlign: "center", fontWeight: "bold" }}>{mensagemErro}</p> : null}
            </>
        )
    }
}

export default Form
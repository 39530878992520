import React, { Component } from "react"
import { MapContainer, ImageOverlay, useMapEvents, Marker, useMap } from 'react-leaflet'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Select from '../../../components/Select'
import helper from "../../../utils/helper"
import ReactDOMServer from 'react-dom/server'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import CamposComandos from "./CamposComandos"
import ReactLoading from 'react-loading'
import L from 'leaflet'
import Alerta from "../../Alerta"
import auth from "../../../utils/auth"
import cfg from "../../../utils/config"
import axios from "axios"
import 'leaflet/dist/leaflet.css'
import './CustomMarker.css'

const MapEventHandler = ({ onClick }) => {
    useMapEvents({
        click: onClick,
    })
}

const CustomMarker = (props) => {
    let nome_monitorado = props.nomeMonitorado

    if (nome_monitorado.length > 8) {
        nome_monitorado = nome_monitorado.split("").slice(0, 8).join().replaceAll(",", "")
    }

    return (
        <div className="custom-marker-container">
            <div className="custom-marker">
                <div title={props.nomeMonitorado} className="label-marker">{nome_monitorado}</div>
            </div>
            <div className="custom-marker-gradient"></div>
        </div>
    )
}

const CustomButton = ({ center }) => {
    const map = useMap()

    React.useEffect(() => {
        const button = L.control({ position: 'topright' })

        button.onAdd = function () {
            const btn = L.DomUtil.create('button', 'leaflet-bar')
            btn.innerHTML = 'Recentralizar mapa'
            btn.onclick = function (e) {
                L.DomEvent.stopPropagation(e)
                map.setView(center, map.getZoom())
            }
            return btn
        }

        button.addTo(map)

        return () => {
            button.remove()
        }
    }, [map, center])

    return null
}

class PlantaBaixa extends Component {
    constructor(props) {
        super(props)
        this.state = {
            detalhes: { cliente: null, monitorado: null },
            validacao: { cliente: true, monitorado: true },
            imagem_planta: "",
            file_imagem_planta: "",
            markers: [],
            keySelected: false,
            modalStatus: false,
            isCliente: auth.isClient(),
            clientes: [],
            monitorados: [],
            idPlanta: false,
            cooldown: false,
            resolucaoImagem: [],
            tab: 0,
            requestMapaFinalizada: false,
            edit: false,
            bounds: [[0, 0], [0, 0]],
            zoom: 2,
            center: [0, 0]
        }
        this.mapRef = React.createRef()
    }

    componentDidMount() {
        if (this.props.shared) {
            this.getDataShared()
        } else {
            this.dataInitial()
        }
    }

    dataInitial = () => {
        let { isCliente, detalhes, validacao } = this.state

        if (this.props.supervisorioSelecionado) {
            if (!isCliente) {
                this.getClientes()
            } else {
                this.getMonitorados(false)

                delete detalhes.cliente
                delete validacao.cliente
                this.setState({ detalhes, validacao })
            }

            this.getData()
        }
    }

    getClientes = () => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/', config_me)
            .then((response) => {
                let optionsCliente = []

                response.data.results.forEach(option => {
                    optionsCliente.push({ label: option.nome_fantasia, value: option.id })
                })
                this.setState({ clientes: optionsCliente })
            })
    }

    getMonitorados = (id) => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/'

        if (id) {
            url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/monitorado/?cliente=${id}`
        }

        axios.get(url, config_me)
            .then((response) => {
                let optionsMonitorado = []

                response.data.results.forEach(option => {
                    optionsMonitorado.push({ label: option.nome, value: option.id })
                })

                this.setState({ monitorados: optionsMonitorado })
            })
    }

    getDataShared = () => {
        if (this.props.dataShared) {
            const data = this.props.dataShared
            const resolucao_imagem = [(data.resolucao_imagem.altura / 4), (data.resolucao_imagem.largura / 4)]
            const bounds = [[0, 0], resolucao_imagem]
            const zoom = 2
            const center = resolucao_imagem.length > 0 ? [(bounds[0][0] + bounds[1][0]) / 2, (bounds[0][1] + bounds[1][1]) / 2] : [0, 0]

            this.centerMap(center, zoom)

            this.setState({
                markers: data.estrutura,
                idPlanta: data.id,
                imagem_planta: data.imagem,
                bounds: bounds,
                zoom: zoom,
                center: center
            })
        }

        this.setState({ requestMapaFinalizada: true })
    }

    getData = () => {
        this.setState({ requestMapaFinalizada: false })
        let userInfo = auth.getUserInfo()

        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/supervisorio/${this.props.idSupervisorio}/planta-baixa/`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (response.data.results.length > 0) {
                    const data = response.data.results[0]
                    const resolucao_imagem = [(data.resolucao_imagem.altura / 4), (data.resolucao_imagem.largura / 4)]
                    const bounds = [[0, 0], resolucao_imagem]
                    const zoom = 2
                    const center = resolucao_imagem.length > 0 ? [(bounds[0][0] + bounds[1][0]) / 2, (bounds[0][1] + bounds[1][1]) / 2] : [0, 0]
                    const estrutura = data.estrutura.filter((marker) => data.monitorados.includes(marker.monitorado.value))

                    this.centerMap(center, zoom)

                    this.setState({
                        markers: estrutura,
                        idPlanta: data.id,
                        imagem_planta: data.imagem,
                        bounds: bounds,
                        zoom: zoom,
                        center: center
                    })
                }

                this.setState({ requestMapaFinalizada: true })
            })
    }


    changeImage = (e) => {
        if (e.target.files[0]) {
            let url = URL.createObjectURL(e.target.files[0])
            const selectedFile = e.target.files[0]
            const reader = new FileReader()

            reader.onload = (e) => {
                const img = new Image()

                img.onload = () => {
                    const resolucao_imagem = [(img.height / 4), (img.width / 4)]
                    const bounds = [[0, 0], resolucao_imagem]
                    const zoom = 2
                    const center = [(bounds[0][0] + bounds[1][0]) / 2, (bounds[0][1] + bounds[1][1]) / 2]

                    this.centerMap(center, zoom)

                    this.setState({
                        imagem_planta: url,
                        file_imagem_planta: selectedFile,
                        bounds: bounds,
                        zoom: zoom,
                        center: center
                    })
                }
                img.src = e.target.result
            }
            reader.readAsDataURL(selectedFile)
        }
    }

    handleChange = (option, field) => {
        let detalhes = Object.assign({}, this.state.detalhes)
        detalhes[field] = option

        if (field === 'cliente') {
            detalhes['monitorado'] = null
            this.getMonitorados(option.value)
        }

        this.setState({ detalhes })
    }

    centerMap(center, zoom) {
        const map = this.mapRef.current
        if (map != null) {
            map.setView(center, zoom)
        }
    }

    addMarkerMap = (e) => {
        if (this.state.edit) {
            const { lat, lng } = e.latlng

            this.setState((prevState) => ({
                markers: [...prevState.markers, { cliente: null, monitorado: null, posicao: [String(lat), String(lng)] }],
                modalStatus: true,
                keySelected: this.state.markers.length
            }))
        }
    }

    dragend = (e, index) => {
        const { lat, lng } = e.target._latlng
        const markers = [...this.state.markers]
        const marker = markers.filter((marker, keyMarker) => keyMarker === index)[0]

        marker.posicao = [String(lat), String(lng)]
        markers[index] = marker

        this.setState({ markers })
    }

    deleteMarker = () => {
        let markers = [...this.state.markers]
        let keyDelete = this.state.keySelected

        markers = markers.filter((marker, keyMarker) => keyMarker !== keyDelete)

        this.toggleModal(false, false, true)
        this.setState({ markers })
    }

    validacao = () => {
        const detalhes = this.state.detalhes
        let validacao = Object.assign({}, this.state.validacao)
        let erro = false

        for (let i in detalhes) {
            if (detalhes[i] === null) {
                validacao[i] = false
                erro = true
            } else {
                validacao[i] = true
            }
        }

        this.setState({ validacao })

        return erro
    }

    addInformacaoMarker = (addModal) => {
        let detalhes = this.state.detalhes
        let markers = [...this.state.markers]
        let markerSelected = markers[this.state.keySelected]

        if (this.validacao()) {
            return
        }

        markerSelected.cliente = detalhes.cliente
        markerSelected.monitorado = detalhes.monitorado
        markers[this.state.keySelected] = markerSelected

        if (addModal != true) {
            this.toggleModal(false, false)
        }
        this.setState({ markers })
    }

    toggleModal = (status, keySelected, excluir) => {
        let { isCliente, edit } = this.state
        let key = keySelected !== false ? keySelected : false
        let detalhes = ""

        if (status === false) {
            detalhes = { cliente: null, monitorado: null }

            if (edit && !excluir) {
                let erro = this.validacao()

                if (erro) {
                    return
                } else {
                    this.addInformacaoMarker(true)
                }
            }

            if (!isCliente) {
                this.setState({ monitorados: [] })
            }
        } else {
            let marker = this.state.markers.filter((marker, index) => index === keySelected)[0]
            detalhes = { cliente: marker.cliente ?? null, monitorado: marker.monitorado ?? null }

            if (!isCliente && !this.props.shared) {
                if (marker.cliente) {
                    this.getMonitorados(marker.cliente.value)
                }
            }
        }

        this.setState({ modalStatus: status, keySelected: key, detalhes, validacao: { cliente: true, monitorado: true } })
    }

    saveAction = () => {
        this.setState({ cooldown: true })

        let userInfo = auth.getUserInfo()
        let requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/supervisorio/' + this.props.idSupervisorio + '/planta-baixa/'
        let method = "post"

        if (this.state.idPlanta) {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/supervisorio/' + this.props.idSupervisorio + '/planta-baixa/' + this.state.idPlanta + '/'
            method = "patch"
        }

        let monitorados = []
        this.state.markers.forEach((marker) => {
            monitorados.push(marker.monitorado.value)
        })

        let bodyFormData = new FormData()

        bodyFormData.append("estrutura", JSON.stringify(this.state.markers))
        bodyFormData.append("imagem", this.state.file_imagem_planta)
        bodyFormData.append("monitorados", JSON.stringify(monitorados))

        if (this.state.file_imagem_planta === "") {
            bodyFormData.delete("imagem")
        }

        axios({
            method: method,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                this.setState({ cooldown: false })

                helper.dispatchEvent("showAviso", {
                    message: "Planta baixa salva com sucesso!",
                    callbackClose: () => this.getData()
                })
            })
            .catch((error) => {
                this.setState({ cooldown: false })
                if (error.response.status === 413) {
                    helper.dispatchEvent("showAviso", {
                        message: "Imagem muito grande. Por favor, escolha uma imagem menor.",
                        callback: () => this.setState({ cooldown: false })
                    }
                    )
                }
            })

    }

    createDivIcon = (nome_monitorado) => {
        const htmlString = ReactDOMServer.renderToString(<CustomMarker nomeMonitorado={nome_monitorado} />)

        return L.divIcon({
            className: 'custom-div-icon',
            html: htmlString,
            iconSize: [32, 64],
            iconAnchor: [16, 64],
            popupAnchor: [0, -64],
        })
    }

    handleTab = (index) => {
        this.setState({ tab: index })
    }

    habilitarEdicao = () => {
        this.setState({ edit: !this.state.edit })
    }

    render() {
        const { imagem_planta, modalStatus, isCliente, validacao, clientes, monitorados, detalhes, cooldown, requestMapaFinalizada, edit, zoom, center, bounds } = this.state
        const supervisorioSelecionado = this.props.supervisorioSelecionado

        return (
            <div>
                <Modal className="modal-large" isOpen={modalStatus} toggle={() => this.toggleModal(false, false)}>
                    <ModalHeader toggle={() => this.toggleModal(false, false)}>
                        <span>{detalhes.monitorado ? detalhes.monitorado.label : "Marker"}</span>
                    </ModalHeader>
                    <ModalBody>
                        <Tabs className="tabs-style" selectedIndex={this.state.tab} onSelect={(i) => this.handleTab(i)}>
                            <TabList>
                                {edit ?
                                    <Tab>Selecionar monitorado</Tab>
                                    :
                                    <>
                                        <Tab>Campos e comandos</Tab>
                                        <Tab>Alertas</Tab>
                                    </>
                                }
                            </TabList>

                            {
                                edit ?
                                    <TabPanel>
                                        <div style={{ marginTop: "10px" }} className="row">
                                            {
                                                !isCliente ?
                                                    <div className="col-md-6">
                                                        <label className="required">Cliente</label>
                                                        <Select
                                                            value={detalhes.cliente}
                                                            onChange={(e) => this.handleChange(e, 'cliente')}
                                                            options={clientes}
                                                        />
                                                        {!validacao.cliente ? <span id='validacao-cliente'>Campo não pode ser nulo!</span> : null}
                                                    </div>
                                                    : ""
                                            }
                                            <div className="col-md-6">
                                                <label className="required">Monitorado</label>
                                                <Select
                                                    value={detalhes.monitorado}
                                                    onChange={(e) => this.handleChange(e, 'monitorado')}
                                                    options={monitorados}
                                                />
                                                {!validacao.monitorado ? <span id='validacao-cliente'>Campo não pode ser nulo!</span> : null}
                                            </div>
                                        </div>
                                    </TabPanel>
                                    :
                                    <>
                                        <TabPanel>
                                            <CamposComandos
                                                dataShared={this.props.shared ? { hash: this.props.hash } : false}
                                                monitorado={detalhes.monitorado}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                            <Alerta
                                                dataSharedSupervisory={this.props.shared ? { hash: this.props.hash } : false}
                                                compact
                                                customFilter={`&monitorado=${detalhes.monitorado?.value}`}
                                            />
                                        </TabPanel>
                                    </>
                            }
                        </Tabs>
                    </ModalBody>
                    <ModalFooter>
                        {
                            edit ?
                                <>
                                    <button onClick={this.addInformacaoMarker} className="hdv-btn-forms hdv-btn-green">Concluir</button>
                                    <button onClick={this.deleteMarker} className="hdv-btn-forms hdv-btn-red">Excluir</button>
                                </>
                                : ""
                        }
                    </ModalFooter>
                </Modal>

                <div style={{ display: this.props.shared || isCliente ? "none" : "flex" }} id="group-button-planta" className="hdv-btn-group">
                    <button disabled={imagem_planta === "" || cooldown ? true : false} onClick={this.saveAction} className="hdv-barsuper-button d-flex align-items-center hdv-barsuper-new">
                        <i class="fa fa-cloud-upload-alt fa-2x"></i>Salvar</button>
                    <button disabled={imagem_planta === "" || cooldown ? true : false} className="hdv-barsuper-button d-flex align-items-center hdv-barsuper-new" style={{ backgroundColor: "#F3AC29" }} onClick={this.habilitarEdicao}>
                        <i class="las la-map-marker la-2x"></i>{edit ? "Desabilitar Edição" : "Habilitar Edição"}</button>
                    <button disabled={!supervisorioSelecionado} id="selecionar_planta" className="hdv-barsuper-button d-flex align-items-center hdv-barsuper-new" style={{ backgroundColor: "#D2D2D2", color: !supervisorioSelecionado ? "white" : "black", fontWeight: "normal" }}>
                        {imagem_planta !== "" ? <i class="las la-exchange-alt la-2x"></i> : <i class="las la-file-image la-2x"></i>}
                        {imagem_planta !== "" ? "Trocar Imagem" : "Selecionar Imagem"}
                        <input style={{ display: supervisorioSelecionado ? "block" : "none" }} title="Selecione uma imagem" type="file" id="imagem_planta" onChange={this.changeImage} />
                    </button>
                </div>

                {requestMapaFinalizada ?
                    <MapContainer
                        crs={L.CRS.Simple}
                        style={{ height: 'calc(100vh - 80px)', width: '98.5%', zIndex: "1", borderRadius: 5, marginLeft: "10px", backgroundColor: "#EEECEC", marginTop: "8px" }}
                        center={this.state.center}
                        zoom={this.state.zoom}
                        ref={this.mapRef}>
                        <ImageOverlay url={this.state.imagem_planta} bounds={bounds} />
                        <CustomButton center={center} />
                        <MapEventHandler onClick={this.addMarkerMap} />

                        {this.state.markers.map((marker, index) => {
                            return (
                                <Marker
                                    key={index}
                                    draggable={edit}
                                    position={[marker.posicao[0], marker.posicao[1]]}
                                    icon={this.createDivIcon(marker.monitorado?.label ?? "")}
                                    eventHandlers={{
                                        click: () => this.toggleModal(true, index),
                                        dragend: (e) => this.dragend(e, index)
                                    }}
                                />
                            )
                        })
                        }
                    </MapContainer>
                    : this.props.supervisorioSelecionado ?
                        <div style={{ height: 'calc(100vh - 80px)', alignItems: "center", justifyContent: "center" }} className="row">
                            <ReactLoading className="hdv-report-loading" type="spin" color={"#589bd4"} width={50} />
                        </div>
                        : ""
                }
            </div>
        )
    }
}

export default PlantaBaixa
import React, { Component } from 'react'
import ReactECharts from 'echarts-for-react'
import cfg from '../../../utils/config'
import helper from '../../../utils/helper'
import moment from 'moment'
import auth from '../../../utils/auth'
import axios from 'axios'

class GraficoBarra extends Component {
    rid = "chart-" + helper.randomKey(6)

    state = {
        chart: null,
        lastUpdate: null,
        lastChartUpdate: null,
        chartData: null,
        params: {
            periodo: "",
            descricao: "",
            values: [],
            dates: [],
            valores_anteriores: false,
        },
    }

    componentDidMount = () => {
        if (this.props.dataWidget) {
            let { params } = this.state
            let { dataWidget } = this.props

            params.descricao = decodeURI(dataWidget['descricao'])
            params.periodo = decodeURI(dataWidget.params['periodo'])
            params.valores_anteriores = dataWidget.params.valores_anteriores

            if (!params.valores_anteriores) {
                params.values.push(dataWidget.value)
                params.dates.push(dataWidget.date)
            }

            if (params.valores_anteriores && !dataWidget?.shared) {
                this.getChartData(params)
            }

            this.setState({ params })
            this.updateChart(params.values, params.dates)
        }
    }

    componentWillReceiveProps = (nextProps) => {
        let { lastUpdate, params, chartData } = this.state

        try {
            if (new Date().getTime() - lastUpdate > 60_000) {
                let { dataWidget } = this.props

                if (params.periodo && params.dates.length > 0) {
                    let dateHour = (params.periodo ?? 1) * 60

                    if (params.dates.length >= dateHour) {
                        params.values.shift()
                        params.dates.shift()
                    }
                }

                if (dataWidget?.parametros?.valores_anteriores && !dataWidget?.shared) {
                    let currentHour = new Date().getHours()
                    let currentMinutes = new Date().getMinutes()

                    if (currentMinutes <= 1) {
                        this.getChartData(this.state.params)
                    } else {
                        if (chartData != null && (chartData[currentHour])) {
                            let values = []
                            let dates = []
                            let diff = dataWidget?.value - chartData?.[currentHour]?.ini
                            chartData[currentHour].diff = diff

                            Object.keys(chartData).forEach(key => dates.push(`${key}:00`))

                            dates.forEach((key) => {
                                let index = key.split(":")[0]

                                if (chartData[index]) {
                                    values.push(chartData[index].diff)
                                } else {
                                    values.push(0)
                                }
                            })

                            this.setState({ params: { values, dates }, chartData: chartData })
                            this.updateChart(values, dates)
                        }
                    }
                } else {
                    params.values.push(dataWidget.value)
                    params.dates.push(dataWidget.date)
                }

                this.setState({ params })
                this.updateChart(params.values, params.dates)
            }
        } catch (err) {
            console.error(err)
        }
    }

    convertTimezone = (dataUTC, format) => {
        const systemTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
        try {
            let timezone = auth.getUserInfo()?.timezone ?? this.props.timezone
            if (!timezone) {
                timezone = systemTZ
            }

            return moment.tz(dataUTC, 'UTC').tz(timezone).format(format)
        } catch (err) {
            return moment.tz(new Date().getTime(), 'UTC').tz(systemTZ).format(format)
        }
    }

    getChartData = (params) => {
        let { campo, mqtt } = this.props.dataWidget
        let userInfo = auth.getUserInfo()

        let formData = new FormData()
        formData.append("dispositivos", JSON.stringify([{ id: mqtt, field: campo }]))
        formData.append("grupo", "hour_current")
        formData.append("timestamp_dispositivo_ini", new Date().toLocaleDateString().split("/").reverse().join("-") + " 00:00:00")
        formData.append("timestamp_dispositivo_fim", new Date().toLocaleDateString().split("/").reverse().join("-") + " 23:59:59")

        axios({
            url: `${cfg.base_api_url + cfg.api_v2}/${userInfo.empresa}/reporte/consumo/`,
            method: "POST",
            data: formData,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((res) => {
            try {
                let { data } = res.data
                let values = []
                let dates = []

                Object.keys(data.TOTAL_GROUP).forEach(key => dates.push(`${key}:00`))

                dates.forEach((key) => {
                    let index = key.split(":")[0]

                    if (data.TOTAL_GROUP[index]) {
                        values.push(data.TOTAL_GROUP[index].diff)
                    } else {
                        values.push(0)
                    }
                })

                this.setState({ params: { values, dates }, chartData: data.TOTAL_GROUP })
                this.updateChart(values, dates)
            } catch (err) {
                console.error(err)
            }
        }).catch(console.error)
    }

    updateChart = (values, dates) => {
        let { params } = this.state

        const options = {
            legend: { show: true },
            grid: { top: 30, right: 80, bottom: 30, left: 80 },
            series: [{
                type: 'bar', data: values, name: params.descricao,
                label: { show: false, position: 'top', opacity: '0.8' }
            }],
            xAxis: {
                data: dates,
                nameTextStyle: { fontWeight: '600', fontSize: '12' },
                nameLocation: 'center',
                nameGap: 25
            },
            yAxis: { type: 'value' },
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: { label: { show: true } },
            },
        }

        this.setState({ chart: null })
        setTimeout(() => {
            try {
                const parent = document.querySelector(`#${this.rid}`).parentElement
                const style = { height: `${parent.clientHeight - 80}px`, width: '100%', marginTop: '10px' }

                this.setState({
                    lastUpdate: new Date().getTime(),
                    chart: <ReactECharts option={options} style={style} />
                })
            } catch (err) { }
        }, 10)
    }

    render() {

        return (
            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                {this.props.dataWidget?.ultimoEvento({ showUnity: false })}

                <div id={this.rid}>
                    {this.state.chart}
                </div>
            </div>
        )
    }
}

export default GraficoBarra
import React, { Component } from 'react'
import auth from '../utils/auth'
import axios from 'axios'
import cfg from '../utils/config'

class WarningUser extends Component {
    state = {
        userOrigin: auth.get("__hidroview__userOrigin"),
        userInfo: auth.getUserInfo()
    }

    returnUserOrigin = () => {
        axios.get(cfg.base_api_url + cfg.api_v2 + '/me/',  { headers: { 'Authorization': 'Bearer ' + this.state.userOrigin.token }})
        .then((res) => {
            auth.setUserInfo(res.data, true)
            auth.setToken(this.state.userOrigin.token, true, '__hidroview__access_token')
            auth.clearUserOrigin()

            setTimeout(() => { window.location.reload() }, 1000)
        })
    }

    render() {
        const { userOrigin, userInfo } = this.state

        if(userOrigin) {
            return(
                <div stye className='fixed-user-message'>
                    <div className='box-user-message'>
                        Você está conectado como usuário <strong>{userInfo.nome}</strong> com o perfil <strong>{userInfo.nome_perfil}</strong>. 
                        <span onClick={this.returnUserOrigin}><strong>Retornar ao usuário: {userOrigin.userName}</strong></span>
                    </div>
                </div>
            )
        }
    }
}

export default WarningUser
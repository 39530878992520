import { Button, Modal, ModalBody, ModalHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ModalFooter } from "reactstrap"
import { Link } from "react-router-dom"
import { Component } from "react"
import axios from "axios"
import cfg from "../utils/config"
import auth from "../utils/auth"

class CardParametrizacao extends Component {
    userInfo = auth.getUserInfo()

    state = {
        modalDelete: false
    }

    delete = () => {
        this.setState({ modalDelete: false })

        axios({
            url: cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/campo-customizado/${this.props.parametrizacao.id}/`,
            method: "DELETE",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(() => this.props?.getParametros())
            .catch(console.error)
    }

    render() {
        let { detalhes, parametrizacao } = this.props
        
        return (
            <>
                <Modal isOpen={this.state.modalDelete} toggle={() => this.setState({ modalDelete: !this.state.modalDelete })}>
                    <ModalHeader>Confirmar exclusão</ModalHeader>
                    <ModalBody>
                        <p style={{ fontSize: '14px' }}>Você tem certeza que deseja deletar? Essa ação não poderá ser desfeita</p>
                    </ModalBody>
                    <ModalFooter>
                        <div className="float-right" style={{ marginTop: '20px' }}>
                            <button onClick={() => this.setState({ modalDelete: false })} className="hdv-btn-forms hdv-btn-yellow">Cancelar</button>
                            <button onClick={this.delete} className="hdv-btn-forms hdv-btn-red">Confirmar</button>
                        </div>
                    </ModalFooter>
                </Modal>

                <div className="parametrizacao-btn-editar">
                    <div style={{ height: "90%" }}>
                        <div className="pt-3">
                            <strong>{parametrizacao.descricao}</strong>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-6 pl-4">
                                {(parametrizacao.tipo_grafico === "barra")
                                    ?
                                    <i className="fa fa-chart-bar" id="chart-type"></i>
                                    :
                                    <i className="fa fa-chart-line" id="chart-type"></i>
                                }
                                <br />
                                <span>Tipo Gráfico</span>
                            </div>
                            <div className="col-md-6 pr-4">
                                <strong style={{ fontSize: "17px" }}>({parametrizacao.unidade})</strong>
                                <br />
                                <span>Unidade</span>
                            </div>
                        </div>
                    </div>

                    <UncontrolledDropdown direction="down">
                        <DropdownToggle nav>
                            <div className="btns-parametrizacao-novo">
                                <span style={{ textAlign: "center", padding: "6px", fontSize: "13px" }}>Ações</span>
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className="mini-modal">
                            <Link
                                to={{
                                    pathname: `/campo-parametrizado/${parametrizacao?.equipamento}/editar/${parametrizacao.id}`,
                                    state: { id: detalhes.id }
                                }}
                            >
                                <DropdownItem>
                                    <i className="fa fa-pen"></i> Editar
                                </DropdownItem>
                            </Link>
                            <DropdownItem onClick={() => this.setState({ modalDelete: true })}>
                                <i className="fa fa-times"></i> Excluir
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </>
        )
    }
}

export default CardParametrizacao
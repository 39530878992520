import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import auth from "../utils/auth"
import rotas from './RotasMenu'

class LinksAmbiental extends Component {
    componentDidMount() {
        let modulos = auth.getUserInfo().menu
        this.checkToShow(modulos)
    }

    checkToShow(modulos) {
        let menu = modulos?.find((user) => user.item === 'ambiental')?.endpoints
        let links = document.querySelectorAll("#item-menu")

        if (auth.isMasterOrAdmin()) {
            return
        }

        links.forEach(a => {
            let href = a.attributes.href?.nodeValue

            if (href != "/") {
                if (menu.includes(rotas['ambiental'][href]) === false) {
                    a.style.display = "none"
                }
            }
        })

        document.querySelectorAll("#group").forEach((group) => {
            if (group.querySelectorAll("a:not([style*='display: none'])").length === 0) {
                group.querySelector("span").style.display = "none"
            }
        })

        document.querySelectorAll(".hdv-menu-parent").forEach((group) => {
            if (group.querySelectorAll("a:not([style*='display: none'])").length === 0) {
                group.style.display = "none"
            }
        })
    }

    render() {
        return (
            <div id="scroll-menu">
                <div className={"hdv-menu-grupo"}>
                    Cadastros
                </div>
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <Link className="hdv-submenu" id="item-menu" to="/sideccr">
                        <div className={this.props.pathIncludes("/sideccr")}>
                            <i className="fa fa-network-wired fa-20px"></i> SIDECC-R
                        </div>
                    </Link>
                    <Link className="hdv-submenu" id="item-menu" to="/mira">
                        <div className={this.props.pathIncludes("/mira")}>
                            <i className="fa fa-network-wired fa-20px"></i> MIRA
                        </div>
                    </Link>
                    <Link className="hdv-submenu" id="item-menu" to="/ana">
                        <div className={this.props.pathIncludes("/ana")}>
                            <i className="fa fa-network-wired fa-20px"></i> ANA
                        </div>
                    </Link>
                </div>

                <div className={"hdv-menu-grupo"}>
                    <span>Relatórios</span>
                </div>
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <Link className="hdv-submenu" id="item-menu" to="/relatorio-inema">
                        <div className={this.props.pathIncludes("/relatorio-inema")}>
                            <i className="fa fa-file-alt fa-20px"></i> Inema
                        </div>
                    </Link>
                    <Link className="hdv-submenu" id="item-menu" to="/relatorio-serhma">
                        <div className={this.props.pathIncludes("/relatorio-serhma")}>
                            <i className="fa fa-file-alt fa-20px"></i> Serhma
                        </div>
                    </Link>
                </div>
            </div>
        )
    }
}

export default LinksAmbiental
import React from 'react'
import Select from '../../components/Select'
import { Link, Redirect } from 'react-router-dom'
import ButtonsForm from '../../components/ButtonsForm'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import helper from '../../utils/helper'
import axios from 'axios'


export default class Form extends React.Component {

    state = {
        detalhes: { descricao: null, intervencao: null, cliente: null, monitorado: null, equipamento: null, vazao: null, volume: null, duracao: null, usuario: null, senha: null, ativo: false },
        validacao: { descricao: true, intervencao: true, cliente: true, monitorado: true, equipamento: true, vazao: true, volume: true, duracao: true, usuario: true, senha: true },
        clientes: [],
        monitorados: [],
        equipamentos: [],
        campos: [],
        isCliente: auth.getUserInfo()?.cliente
    }

    componentDidMount() {
        const { detalhes, isCliente } = this.state

        if (this.props.match.params.id) {
            this.getData()
        } else {
            if(isCliente) {
                this.getMonitorado()
                delete detalhes.cliente
                this.setState({ detalhes })
            } else {
                this.getClientes()
            }
        }
    }

    getData = () => {
        const userInfo = auth.getUserInfo()
        const config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + `/${userInfo.empresa}/ana/${this.props.match.params.id}/`, config_me)
        .then(res => {
            const { detalhes, isCliente } = this.state
            const result = res?.data

            detalhes["descricao"] = result.descricao
            detalhes["intervencao"] = result.intervencao
            detalhes["monitorado"] = result.detalhes.monitorado
            detalhes["equipamento"] = result.detalhes.equipamento
            detalhes["vazao"] = result.detalhes.vazao
            detalhes["volume"] = result.detalhes.volume
            detalhes["duracao"] = result.detalhes.duracao
            detalhes["usuario"] = result.usuario
            detalhes["senha"] = result.senha
            detalhes["ativo"] = result.ativo
            
            if (isCliente) {
                delete detalhes.cliente
            } else {
                detalhes['cliente'] = result.detalhes.cliente
                this.getClientes()
            }

            this.getMonitorado(result.cliente)
            this.getEquipamento(result.monitorado)
            this.getCamposEquipamento(result.detalhes.equipamento.value)

            this.setState({ detalhes })
        })
    }

    getClientes = () => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/acesso/modulo/2/revenda/` + `?monitorado_restrito=${userInfo.monitorado_restrito}`, config_me)
        .then((res) => {
            let optionsCliente = []
            res.data.forEach((c) => {
                optionsCliente.push({ "value": c.cliente__id, "label": c.cliente__nome_fantasia })
            })

            this.setState({ clientes: optionsCliente })
        })
    }

    getMonitorado = (id_cliente) => {
        let userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()

        bodyFormData.append('cliente_id', id_cliente ?? userInfo.cliente)

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/filtro/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then((response) => {
            let optionsMonitorado = []
            response.data.forEach((c) => {
                optionsMonitorado.push({ "value": c.id, "label": c.nome })
            })
            this.setState({ monitorados: optionsMonitorado })
        })
        .catch(console.error)
    }

    getEquipamento = (id_monitorado) => {
        let userInfo = auth.getUserInfo()
        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-equipamento/' + id_monitorado + '/',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then((res) => {
            let optionsEquipamento = []
            res.data.equipamentos.forEach((equipamento) => optionsEquipamento.push(equipamento))
            this.setState({ equipamentos: optionsEquipamento })
        })
        .catch(console.error)
    }

    getCamposEquipamento = (id_equipamento) => {
        let userInfo = auth.getUserInfo()

        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/campo-customizado/?equipamento=${id_equipamento}`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then((res) => {
            let fields = []
            res.data?.results.forEach((field) => fields.push({ label: field.descricao, value: field.id }))

            this.setState({ campos: fields })
        })
        .catch(error => console.log(error))
    }

    handleChange = (selectedOption, field) => {
        let obj = Object.assign({}, this.state.detalhes)
        let canotClearFields = ["descricao", "intervencao", "usuario", "senha", "ativo"]
        let validate = true

        obj[field] = selectedOption
        
        if (field === "cliente") {
            this.getMonitorado(selectedOption.value)
            canotClearFields.push("cliente")
        } else if (field === 'monitorado') {
            this.getEquipamento(selectedOption.value)
            canotClearFields.push("cliente", "monitorado")
        } else if (field === 'equipamento') {
            this.getCamposEquipamento(selectedOption.value)
            canotClearFields.push("cliente", "monitorado", "equipamento")
        } else {
            validate = false
        }

        if (validate) {
            for (let i in obj) {
                !canotClearFields.includes(i) ? obj[i] = null : null
            }

            this.setState({ campos: [] })
        }

        this.setState({ detalhes: obj })
    }

    handleChangeInput = (e) => {
        let detalhes = Object.assign({}, this.state.detalhes)
        detalhes[e.target.id] = e.target.type === "checkbox" ? e.target.checked : e.target.value
        this.setState({ detalhes })
    }

    validation = () => {
        const { validacao, detalhes } = this.state

        let notValidate = false
        let validateForm = validacao

        for (let key in detalhes) {
            if (detalhes[key] === null || detalhes[key] === "") {
                validateForm[key] = false
                notValidate = true
            } else
                validateForm[key] = true
        }

        this.setState({ validacao: validateForm })

        return notValidate
    }

    saveAction = (novoCadastro) => {
        this.setState({ cooldown: true })
        const { detalhes, isCliente } = this.state

        if (this.validation()) {
            this.setState({ cooldown: false })
            return
        }

        let userInfo = auth.getUserInfo()
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_v2 + `/${userInfo.empresa}/ana/` + this.props.match.params.id + '/'
            verb = 'PATCH'
        } else {
            requestURL = cfg.base_api_url + cfg.api_v2 + `/${userInfo.empresa}/ana/`
            verb = 'POST'
        }
    
        const bodyFormData = {
            descricao: detalhes.descricao,
            intervencao: detalhes.intervencao,
            cliente: isCliente ? userInfo.cliente : detalhes.cliente.value,
            monitorado: detalhes.monitorado.value,
            equipamento: detalhes.equipamento.value,
            vazao: detalhes.vazao.value,
            volume: detalhes.volume.value,
            duracao: detalhes.duracao.value,
            usuario: detalhes.usuario,
            senha: detalhes.senha,
            ativo: detalhes.ativo
        }

        axios({
            method: verb,
            url: requestURL,
            data: JSON.stringify(bodyFormData),
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then(res => {
            if (novoCadastro) {
                window.location.reload()
            } else {
                helper.dispatchEvent("showAviso", {
                    message: `${verb === "POST" ? 'Criado' : 'Editado'} com sucesso`,
                    callback: () => this.setState({ redirect: true, path: "/ana" }),
                    callbackClose: verb === "POST" ?
                        () => {
                            window.location.href = `/ana/${res.data.id}/editar`
                            setTimeout(() => { window.location.reload() }, 1000)
                        }
                    : null
                })
            }
        })
        .catch((error) => {
            helper.dispatchEvent("showAviso", {
                message: error.response.data.cliente[0],
            })
        })

        setTimeout(() => this.setState({ cooldown: false }), 2500)
    }

    render() {
        const { validacao, detalhes, isCliente } = this.state
        const clienteOption = detalhes.cliente
        const monitoradoOption = detalhes.monitorado
        const equipamentoOption = detalhes.equipamento

        if (this.state.redirect === true) {
            return <Redirect to={{ pathname: '/redirect', state: { pathto: this.state.path } }} />
        }

        return (
            <div>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        {this.props.match.params.id ? <h4>Editar ANA</h4> : <h4>Novo ANA</h4>}
                        <div className="gray-background">
                            <i className="fa fa-network-wired fa-2x"></i>
                        </div>
                    </span>
                </div>

                <div className="hdv-form-default">
                    <div className="row">
                        <div className="col-md-6">
                            <label className='required'>Descrição</label>
                            <input
                                type='text'
                                value={detalhes.descricao}
                                id="descricao"
                                onChange={(e) => this.handleChangeInput(e)}
                            />
                            {!validacao.descricao ? <span id='validacao-descricao'>Campo não pode ser nulo!</span> : null}
                        </div>
                        <div className="col-md-6">
                            <label className='required'>Intervenção</label>
                            <input 
                                type='text'
                                value={detalhes.intervencao}
                                id="intervencao"
                                onChange={(e) => this.handleChangeInput(e)}
                            />
                            {!validacao.intervencao ? <span id='validacao-intervencao'>Campo não pode ser nulo!</span> : null}
                        </div>
                    </div>

                    <div className="row">
                        <div style={{ display: isCliente ? "none" : "block" }} className="col-md-4">
                            <label className="required">Cliente</label>
                            <Select
                                value={clienteOption}
                                onChange={(e) => this.handleChange(e, 'cliente')}
                                options={this.state.clientes}
                            />
                            {!validacao.cliente ? <span id='validacao-cliente'>Campo não pode ser nulo!</span> : null}
                        </div>
                        <div className={isCliente ? "col-md-6" : "col-md-4"}>
                            <label className="required">Monitorado</label>
                            <Select
                                value={monitoradoOption}
                                onChange={(e) => this.handleChange(e, 'monitorado')}
                                options={this.state.monitorados}
                            />
                            {!validacao.monitorado ? <span id='validacao-monitorado'>Campo não pode ser nulo!</span> : null}
                        </div>
                        <div className={isCliente ? "col-md-6" : "col-md-4"}>
                            <label className="required">Equipamento</label>
                            <Select
                                value={equipamentoOption}
                                onChange={(e) => this.handleChange(e, 'equipamento')}
                                options={this.state.equipamentos}
                            />
                            {!validacao.equipamento ? <span id='validacao-equipamento'>Campo não pode ser nulo!</span> : null}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <label className="required">Vazão</label>
                            <Select
                                value={detalhes.vazao}
                                onChange={(e) => { this.handleChange(e, 'vazao') }}
                                options={this.state.campos}
                            />
                            {!validacao.vazao ? <span id='validacao-vazao'>Campo não pode ser nulo!</span> : null}
                        </div>
                        <div className="col-md-4">
                            <label className="required">Volume</label>
                            <Select
                                value={detalhes.volume}
                                onChange={(e) => { this.handleChange(e, 'volume') }}
                                options={this.state.campos}
                            />
                            {!validacao.volume ? <span id='validacao-volume'>Campo não pode ser nulo!</span> : null}
                        </div>
                        <div className="col-md-4">
                            <label className="required">Duração</label>
                            <Select
                                value={detalhes.duracao}
                                onChange={(e) => { this.handleChange(e, 'duracao') }}
                                options={this.state.campos}
                            />
                            {!validacao.duracao ? <span id='validacao-duracao'>Campo não pode ser nulo!</span> : null}
                        </div>
                    </div>

                    <div className="row">                    
                        <div className="col-md-4">
                            <label className="required">Usuário</label>
                            <input
                                type="text"
                                value={detalhes.usuario}
                                id="usuario"
                                onChange={(e) => this.handleChangeInput(e)}
                            />
                            {!validacao.usuario ? <span id='validacao-usuario'>Campo não pode ser nulo!</span> : null}
                        </div>
                        <div className="col-md-4">
                            <label className="required">Senha</label>
                            <input
                                className="hdv-form-system-password-field"
                                type="passworgd"
                                value={detalhes.senha}
                                id="senha"
                                onChange={(e) => this.handleChangeInput(e)}
                            />
                            {!validacao.senha ? <span id='validacao-senha'>Campo não pode ser nulo!</span> : null}
                        </div>
                        <div className="col-md-4">
                            <label htmlFor='ativo' className="required">Habilitado</label>
                            <input
                                type="checkbox"
                                id="ativo"
                                checked={detalhes.ativo}
                                onChange={(e) => this.handleChangeInput(e)}
                            />
                        </div>
                    </div>
                </div>

                <div className="hdv-btn-group">
                    <ButtonsForm
                        route="/ana"
                        edit={this.props.match.params.id ? true : false}
                        cooldown={this.state.cooldown}
                        buttons={[
                            {
                                method: "post",
                                button: <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                            },

                            {
                                method: "post",
                                button: <button style={{ display: this.props.match.params.id ? "none" : "inline-block" }} onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-greenlight">Salvar e Cadastrar um Novo</button>
                            },

                            {
                                method: "patch",
                                button: <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                            },
                        ]}
                    />
                    <Link to="/ana">
                        <button className="hdv-btn-forms hdv-btn-yellow">Cancelar</button>
                    </Link>
                </div>
            </div>
        )
    }
}
import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ButtonsForm from '../../components/ButtonsForm'
import ProgressAcessos from '../../components/ProgressAcessos'
import { Link, Redirect } from 'react-router-dom'
import { components } from 'react-select'
import validator from 'validator'
import Select from '../../components/Select'
import helper from '../../utils/helper'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'


class Form extends Component {
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        detalhes: { "razao_social": "", "nome_fantasia": "", "pessoa_tipo": "", "cpf_cnpj": "", "setor": "", "status": "ativo", "perfil": "", "email_administrador": "" },
        enderecos: [],
        contatos: [],
        contato: { "contato": "", "telefone": "", "celular": "", "email": "" },
        endereco: { "descricao": "", "endereco": "", "bairro": "", "municipio": "", "estado": "", "cep": "" },
        acesso: {},
        id_editando_contato: false,
        id_editando_endereco: false,
        modal: { enderecos: false, contatos: false, acessos: false },
        formerrors: [],
        selectedOption: null,
        tipoAcessos: [],
        cooldown: false,
        modulos: [],
        tab: 0,
        isMasterOrAdmin: auth.isMasterOrAdmin(),
    }

    componentWillMount = () => {
        this.getInitialData()
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }

        if (window.location.search === "?modulo=true") {
            this.setState({ tab: 3 })
        }
    }

    getInitialData = () => {
        let userInfo = auth.getUserInfo()

        let requestArray = [
            axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/perfil/', this.configMe),
            axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/acesso/modulo/?meus_acessos_modulo=true', this.configMe),
        ]

        axios.all(requestArray).then(axios.spread((perfis, acessos) => {
            let optionsPerfis = []
            let tiposAcessoOptions = []

            if (perfis.data?.results?.length > 0) {
                perfis.data.results.forEach((c) => optionsPerfis.push({ "value": c.id, "label": c.nome }))
            }

            if (acessos.status === 200) {
                acessos.data.total?.forEach(tipo => {
                    let find = acessos.data.utilizados.find(u => u.acesso_tipo === tipo.acesso_tipo)

                    tiposAcessoOptions.push({
                        label: tipo.acesso_tipo__descricao,
                        value: tipo.acesso_tipo,
                        isDisabled: find?.tcount >= tipo?.tcount
                    })
                })
            }

            this.setState({ perfis: optionsPerfis, tipoAcessos: tiposAcessoOptions })
        }))
    }

    getData = () => {
        let userInfo = auth.getUserInfo()
        const baseUrl = cfg.base_api_url + cfg.api_v2 + '/'

        let requestArray = [
            axios.get(baseUrl + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/', this.configMe),
            axios.get(baseUrl + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/endereco/', this.configMe),
            axios.get(baseUrl + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/contato/', this.configMe),
            axios.get(baseUrl + userInfo.empresa + `/acesso/modulo/?${this.state.isMasterOrAdmin ? 'meus_acessos_modulo=true' : ''}&cliente=` + this.props.match.params.id, this.configMe),
        ]

        axios.all(requestArray).then(axios.spread((detalhes, enderecos, contatos, modulos) => {
            delete detalhes.data.id
            let enderecoItens = []
            let modulosItens = []
            let contatoItens = []

            for (let i in detalhes.data) {
                if (detalhes.data[i] == null) {
                    detalhes.data[i] = ""
                }
            }

            if (enderecos.status === 200) {
                enderecoItens = enderecos.data.results
            }

            if (contatos.status === 200) {
                contatoItens = contatos.data.results
            }

            if (modulos.status === 200) {
                modulosItens = modulos.data.results
            }

            this.setState({
                detalhes: detalhes.data, enderecos: enderecoItens, modulos: modulosItens, contatos: contatoItens,
                selectedOption: { value: detalhes.data.perfil, label: detalhes.data.perfil_nome }
            })
        }))
    }

    handleChange = (e) => {
        let obj = this.state.detalhes
        obj[e.target.id] = e.target.value
        document.querySelector(`#validacao-${e.target.name}`).innerHTML = ''

        this.setState({ "detalhes": obj })
    }

    handleEnderecoChange = (e) => {
        let obj = this.state.endereco
        obj[e.target.id] = e.target.value
        if (document.querySelector(`#validacao-${e.target.name}`))
            document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""

        this.setState({ "endereco": obj })
    }

    handleContatoChange = (e) => {
        let obj = this.state.contato
        obj[e.target.id] = e.target.value
        this.setState({ "contato": obj })
        if (document.querySelector(`#validacao-${e.target.name}`))
            document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""
    }

    handleChangeSelect = (selectedOption, field) => {
        let obj = this.state.detalhes
        obj[field] = selectedOption.value
        if (document.querySelector(`#validacao-${field.slice(0, -3)}`))
            document.querySelector(`#validacao-${field.slice(0, -3)}`).innerHTML = ""

        this.setState({ selectedOption, detalhes: obj })
    }

    addNovoEndereco = () => {
        if (this.props.match.params.id) {

            let userInfo = auth.getUserInfo()
            let bodyFormData = new FormData()
            let verb = ""
            let id_update = ""
            let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/endereco/'

            for (let key in this.state.endereco) {
                bodyFormData.append(key, this.state.endereco[key])
            }

            if (Number.isInteger(this.state.id_editando_endereco) === true) {
                verb = "patch"
                id_update = this.state.enderecos[this.state.id_editando_endereco]['id']
                url += id_update + '/'
            } else {
                verb = "post"
            }

            axios({
                method: verb,
                url: url,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            }).then(() => {
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
                axios.get(url, config_me).then((res) => {
                    let endereco = { "descricao": "", "endereco": "", "bairro": "", "municipio": "", "estado": "", "cep": "" }
                    this.setState({ "endereco": endereco, "enderecos": [res.data], "id_editando_endereco": false })
                    this.handleModal("enderecos")
                })
            }).catch((error) => {
                let erros = Object.entries(error.response.data)
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span) span.innerHTML = erros[i][1]
                    }
                }
            })

        } else {
            let endereco = this.state.endereco
            let enderecos = this.state.enderecos
            let fields_errors = []

            Object.keys(endereco).forEach((key) => {
                if (validator.isEmpty(endereco[key])) {
                    fields_errors.push(key)
                }
            })

            if (fields_errors.length === 0) {
                if (Number.isInteger(this.state.id_editando_endereco)) {
                    enderecos[this.state.id_editando_endereco] = endereco
                } else {
                    enderecos.push(endereco)
                }
                endereco = { "descricao": "", "endereco": "", "bairro": "", "municipio": "", "estado": "", "cep": "" }
                this.setState({ "enderecos": enderecos, "endereco": endereco, "id_editando_endereco": false, "formerrors": [] })
            } else {
                let formerror = []
                fields_errors.forEach((value, key) => {
                    let error = value
                    formerror.push(error)
                })
                this.setState({ formerrors: formerror })
            }
        }
    }

    cancelEditEndereco = () => {
        let endereco = { "descricao": "", "endereco": "", "bairro": "", "municipio": "", "estado": "", "cep": "" }
        this.setState({ "endereco": endereco, "id_editando_endereco": false })
        this.handleModal("enderecos")
    }

    addNovoContato = () => {
        if (this.props.match.params.id) {
            let userInfo = auth.getUserInfo()
            let bodyFormData = new FormData()
            let verb = ""
            let id_update = ""
            let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/contato/'

            for (let key in this.state.contato) {
                bodyFormData.append(key, this.state.contato[key])
            }

            if (Number.isInteger(this.state.id_editando_contato) === true) {
                verb = "patch"
                id_update = this.state.contatos[this.state.id_editando_contato]['id']
                url += id_update + '/'
            } else {
                verb = "post"
            }

            axios({
                method: verb,
                url: url,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then(() => {
                    let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
                    axios.get(url, config_me).then((res) => {
                        let contato = { "contato": "", "telefone": "", "celular": "", "email": "" }
                        this.setState({ "contato": contato, "contatos": [res.data], "id_editando_contato": false })
                        this.handleModal("contatos")
                    })
                })
                .catch((error) => {
                    let erros = Object.entries(error.response.data)
                    if (Array.isArray(erros)) {
                        for (let i = 0; i < erros.length; i++) {
                            let span = document.querySelector(`#validacao-${erros[i][0]}`)
                            if (span)
                                span.innerHTML = erros[i][1]
                        }
                    }
                })

        } else {
            let contato = this.state.contato
            let contatos = this.state.contatos
            let fields_errors = []

            Object.keys(contato).forEach((key) => {
                if (validator.isEmpty(contato[key])) {
                    fields_errors.push(key)
                }
            })

            if (fields_errors.length === 0) {
                if (this.state.id_editando_contato !== false) {
                    contatos[this.state.id_editando_contato] = contato
                } else {
                    contatos.push(contato)
                }
                contato = { "contato": "", "telefone": "", "celular": "", "email": "" }
                this.setState({ "contatos": contatos, "contato": contato, "id_editando_contato": false, "formerrors": [] })
            } else {
                let formerror = []
                fields_errors.map((value, key) => {
                    let error = value
                    formerror.push(error)
                    return true
                })
                this.setState({ formerrors: formerror })
            }
        }
    }

    removeItem = (i, source) => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        if (this.state[source][i]['id']) {
            if (source === "enderecos") {
                let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/endereco/'

                axios.delete(url + this.state[source][i]['id'] + '/', config_me).then(() => {
                    axios.get(url, config_me).then((res) => {
                        let endereco = { "descricao": "", "endereco": "", "bairro": "", "municipio": "", "estado": "", "cep": "" }
                        this.setState({ "endereco": endereco, "enderecos": res.data.results, "id_editando_endereco": false })
                    })
                })
            } else {
                let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/contato/'

                axios.delete(url + this.state[source][i]['id'] + '/', config_me).then(() => {
                    axios.get(url, config_me).then((res) => {
                        let contato = { "contato": "", "telefone": "", "celular": "", "email": "" }
                        this.setState({ "contato": contato, "contatos": res.data.results, "id_editando_contato": false })
                    })
                })
            }
        } else {
            let data = this.state[source]
            delete data[i]
            let newdata = {}
            newdata[source] = data
            this.setState(newdata)
        }
    }

    cancelEditContato = () => {
        let contato = { "contato": "", "telefone": "", "celular": "", "email": "" }
        this.setState({ "contato": contato, "id_editando_contato": false })
        this.handleModal("contatos")
    }

    checkErrorField = (field) => {
        return this.state.formerrors.includes(field) ? "hdv-form-error" : ""
    }

    createRows = (field, source, campo_principal, campo_secundario) => {
        return this.state[source].map((campo, i) =>
            <tr key={i}>
                <td>
                    {campo[campo_principal]}
                </td>
                <td>
                    {campo[campo_secundario]} {campo['id']}
                </td>
                <td className="hdv-subtable-action-td">
                    <button
                        disabled={(parseInt(this.state["id_editando_" + field]) === i)}
                        className="hdv-btn-forms hdv-btn-yellow"
                        onClick={() => {
                            let data = JSON.parse(JSON.stringify(this.state[source]))
                            let newdata = {}
                            newdata[field] = data[i]
                            newdata["id_editando_" + field] = i
                            this.setState(newdata)
                            this.handleModal(source)
                        }}>
                        Editar
                    </button>
                    <button
                        disabled={(parseInt(this.state["id_editando_" + field]) === i)}
                        onClick={() => { this.removeItem(i, source) }}
                        className="hdv-btn-forms hdv-btn-red">
                        Deletar
                    </button>
                </td>
            </tr>
        )
    }

    saveAction = (novoCadastro) => {
        this.setState({ cooldown: true })

        if (this.props.match.params.id) {
            let userInfo = auth.getUserInfo()
            let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/'
            let bodyFormData = new FormData()

            for (let key in this.state.detalhes) {
                bodyFormData.append(key, this.state.detalhes[key])
            }

            if (!this.state.detalhes.cpf_cnpj) {
                bodyFormData.delete("cpf_cnpj")
            }

            if (this.state.detalhes.nome_fantasia.length > 50) {
                if (document.querySelector("#validacao-nome_fantasia"))
                    document.querySelector("#validacao-nome_fantasia").innerHTML = "Este campo não pode ter mais que 50 caracteres."

                setTimeout(() => this.setState({ cooldown: false }), 1000)
                return
            }

            axios({
                method: 'patch',
                url: url,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then(() => {
                    helper.dispatchEvent("showAviso", {
                        message: 'Cliente editado com sucesso',
                        callback: () => this.setState({ redirect: true, path: "/cliente" })
                    })
                })
                .catch((error) => {
                    let erros = Object.entries(error.response.data)
                    if (Array.isArray(erros)) {
                        for (let i = 0; i < erros.length; i++) {
                            let span = document.querySelector(`#validacao-${erros[i][0]}`)
                            if (span) span.innerHTML = erros[i][1]
                        }
                    }
                })
        } else {
            let userInfo = auth.getUserInfo()
            let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/'
            let bodyFormData = new FormData()

            if (this.state.detalhes.nome_fantasia.length > 50) {
                if (document.querySelector("#validacao-nome_fantasia"))
                    document.querySelector("#validacao-nome_fantasia").innerHTML = "Este campo não pode ter mais que 50 caracteres."

                setTimeout(() => this.setState({ cooldown: false }), 1000)
                return
            }

            for (let key in this.state.detalhes) {
                bodyFormData.append(key, this.state.detalhes[key])
            }

            if (!this.state.detalhes.cpf_cnpj) {
                bodyFormData.delete("cpf_cnpj")
            }

            axios({
                method: 'post',
                url: url,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            }).then((res) => {
                if (novoCadastro) {
                    window.location.reload()
                } else {
                    helper.dispatchEvent("showAviso", {
                        message: 'Cliente criado com sucesso',
                        callback: () => this.setState({
                            redirect: true,
                            path: "/cliente"
                        }),
                        callbackClose: () => {
                            window.location.href = `/cliente/${res.data.id}/editar`
                            setTimeout(() => {
                                window.location.reload()
                            }, 1000)
                        }
                    })
                }
            }).catch((error) => {
                let erros = Object.entries(error.response.data)
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })
        }

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    handleModal = (state, value = null) => {
        let { modal } = this.state
        modal[state] = value !== null ? value : !modal[state]
        this.setState({ modal })
    }

    addAcessoModulo = async () => {
        let { acesso, modulos } = this.state
        const userInfo = auth.getUserInfo()
        const cliente = this.props.match.params.id

        if (acesso?.value) {
            const filter = modulos?.filter(m => m.acesso_tipo.id == acesso?.value && m.cliente.id == cliente)

            if (filter?.length > 0) {
                helper.dispatchEvent("showAviso", { message: 'O Cliente já possuí acesso à esse módulo!' })
                return
            }

            this.setState({ cooldown: true })
            const url = `${cfg.base_api_url + cfg.api_v2}/${userInfo.empresa}/acesso/modulo/cliente/`
            let bodyFormData = new FormData()
            bodyFormData.append("acesso_tipo", acesso.value)
            bodyFormData.append("cliente", cliente)

            await axios({
                method: 'PATCH',
                url: url,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            }).then(() => {
                let requestArray = [
                    axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/acesso/modulo/?${this.state.isMasterOrAdmin ? 'meus_acessos_modulo=true' : ''}&cliente=` + cliente, this.configMe),
                    axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/acesso/modulo/?meus_acessos_modulo=true', this.configMe),
                ]
    
                axios.all(requestArray)
                .then(axios.spread((modulos, acessos) => {
                    let tiposAcessoOptions = []
                
                    acessos.data.total?.forEach(tipo => {
                        let find = acessos.data.utilizados.find(u => u.acesso_tipo === tipo.acesso_tipo)
    
                        tiposAcessoOptions.push({
                            label: tipo.acesso_tipo__descricao,
                            value: tipo.acesso_tipo,
                            isDisabled: find?.tcount >= tipo?.tcount
                        })
                    })
    
    
                    this.setState({ modulos: modulos.data.results, tipoAcessos: tiposAcessoOptions })
                }))
            }).catch(console.error)

            setTimeout(() => this.setState({ cooldown: false }), 1000)
            this.handleModal("acessos", false)
        }
    }

    deleteAcessoModulo = (modulo) => {
        const cliente = this.props.match.params.id
        const userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()
        bodyFormData.append("acesso_tipo", modulo.acesso_tipo.id)
        bodyFormData.append("cliente", cliente)

        axios({
            method: 'DELETE',
            url: `${cfg.base_api_url + cfg.api_v2}/${userInfo.empresa}/acesso/modulo/cliente/`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(() => {
            let requestArray = [
                axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/acesso/modulo/?${this.state.isMasterOrAdmin ? 'meus_acessos_modulo=true' : ''}&cliente=` + cliente, this.configMe),
                axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/acesso/modulo/?meus_acessos_modulo=true', this.configMe),
            ]

            axios.all(requestArray)
            .then(axios.spread((modulos, acessos) => {
                let tiposAcessoOptions = []
            
                acessos.data.total?.forEach(tipo => {
                    let find = acessos.data.utilizados.find(u => u.acesso_tipo === tipo.acesso_tipo)

                    tiposAcessoOptions.push({
                        label: tipo.acesso_tipo__descricao,
                        value: tipo.acesso_tipo,
                        isDisabled: find?.tcount >= tipo?.tcount
                    })
                })


                this.setState({ modulos: modulos.data.results, tipoAcessos: tiposAcessoOptions })
            }))
            
        }).catch(console.error)
    }

    handleTab = (index) => {
        this.setState({ tab: index })
    }

    render() {
        const { selectedOption, modal, path, cooldown } = this.state
        const { Option } = components

        if (this.state.redirect === true) {
            return <Redirect to={{ pathname: '/redirect', state: { pathto: path } }} />
        }

        const IconOption = (props) => {
            const disabled = props.data.isDisabled
            return (
                <Option {...props}>
                    <span className="d-flex align-items-center">
                        <i style={{ fontSize: '16px', color: disabled ? "#cf2b2b" : '#2bb33f', borderRadius: "8px" }} className="fa fa-square mr-1"></i>
                        <b style={{ color: '#222', fontWeight: 'normal', opacity: disabled ? 0.7 : 1 }}>{props.data.label}</b>
                    </span>
                </Option>
            )
        }

        return (
            <div>
                <Modal isOpen={modal.enderecos} toggle={() => this.handleModal("enderecos")} size="lg">
                    <ModalHeader toggle={() => this.handleModal("enderecos")}>
                        Endereço
                    </ModalHeader>
                    <ModalBody>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="descricao">Descrição</label>
                                    <input
                                        className={this.checkErrorField("descricao")}
                                        type="text" name="descricao" id="descricao" value={this.state.endereco.descricao} onChange={this.handleEnderecoChange} />
                                    <span id="validacao-descricao"></span>
                                </div>
                                <div className="col-md-8">
                                    <label htmlFor="endereco">Endereço</label>
                                    <input
                                        className={this.checkErrorField("endereco")}
                                        type="text" name="endereco" id="endereco" value={this.state.endereco.endereco} onChange={this.handleEnderecoChange} />
                                    <span id="validacao-endereco"></span>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="bairro">Bairro</label>
                                    <input
                                        className={this.checkErrorField("bairro")}
                                        type="text" name="bairro" id="bairro" value={this.state.endereco.bairro} onChange={this.handleEnderecoChange} />
                                    <span id="validacao-bairro"></span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="municipio">Município</label>
                                    <input
                                        className={this.checkErrorField("municipio")}
                                        type="text" name="municipio" id="municipio" value={this.state.endereco.municipio} onChange={this.handleEnderecoChange} />
                                    <span id="validacao-municipio"></span>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="estado">Estado</label>
                                    <input
                                        className={this.checkErrorField("estado")}
                                        type="text" name="estado" id="estado" value={this.state.endereco.estado} onChange={this.handleEnderecoChange} />
                                    <span id="validacao-estado"></span>
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="cep">CEP</label>
                                    <input
                                        className={this.checkErrorField("cep")}
                                        type="text" name="cep" id="cep" value={this.state.endereco.cep} onChange={this.handleEnderecoChange} />
                                    <span id="validacao-cep"></span>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <button className={(Number.isInteger(this.state.id_editando_endereco)) ? "hdv-btn-forms hdv-btn-orange" : "hdv-btn-forms hdv-btn-orange hdv-noshow-item"}
                            onClick={this.cancelEditEndereco} > <i className="fa fa-cancel"></i> Cancelar Edição
                        </button>
                        <button
                            className={(Number.isInteger(this.state.id_editando_endereco)) ? "hdv-btn-forms hdv-btn-yellow" : "hdv-btn-forms hdv-btn-blue"}
                            onClick={this.addNovoEndereco}>
                            {(Number.isInteger(this.state.id_editando_endereco)) ? <i className="fa fa-refresh"></i> : <i className="fa fa-plus"></i>}
                            {(Number.isInteger(this.state.id_editando_endereco)) ? "Atualizar Endereço" : "Adicionar Endereço"}
                        </button>
                        <button
                            className={(Number.isInteger(this.state.id_editando_endereco)) ? "hdv-noshow-item" : "hdv-btn-forms hdv-btn-default"}
                            onClick={() => this.handleModal("enderecos")}>
                            Fechar
                        </button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={modal.contatos} toggle={() => this.handleModal("contatos")} size="lg">
                    <ModalHeader toggle={() => this.handleModal("contatos")}>
                        Contato
                    </ModalHeader>
                    <ModalBody>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="contato">Contato</label>
                                    <input
                                        className={this.checkErrorField("contato")}
                                        type="text" name="contato" id="contato" value={this.state.contato.contato} onChange={this.handleContatoChange} />
                                    <span id="validacao-contato"></span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="telefone">Telefone</label>
                                    <input
                                        className={this.checkErrorField("telefone")}
                                        type="text" name="telefone" id="telefone" value={this.state.contato.telefone} onChange={this.handleContatoChange} />
                                    <span id="validacao-telefone"></span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="celular">Celular</label>
                                    <input
                                        className={this.checkErrorField("celular")}
                                        type="text" name="celular" id="celular" value={this.state.contato.celular} onChange={this.handleContatoChange} />
                                    <span id="validacao-celular"></span>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="email">E-mail</label>
                                    <input
                                        className={this.checkErrorField("email")}
                                        type="text" name="email" id="email" value={this.state.contato.email} onChange={this.handleContatoChange} />
                                    <span id="validacao-email"></span>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <button className={(Number.isInteger(this.state.id_editando_contato)) ? "hdv-btn-forms hdv-btn-orange" : "hdv-btn-forms hdv-btn-orange hdv-noshow-item"}
                            onClick={this.cancelEditContato} > <i className="fa fa-cancel"></i> Cancelar Edição
                        </button>
                        <button
                            className={(Number.isInteger(this.state.id_editando_contato)) ? "hdv-btn-forms hdv-btn-yellow" : "hdv-btn-forms hdv-btn-blue"}
                            onClick={this.addNovoContato} >
                            {(Number.isInteger(this.state.id_editando_contato)) ? <i className="fa fa-refresh"></i> : <i className="fa fa-plus"></i>}
                            {(Number.isInteger(this.state.id_editando_contato)) ? "Atualizar Contato" : "Adicionar Contato"}
                        </button>

                        <button
                            className={(Number.isInteger(this.state.id_editando_contato)) ? "hdv-noshow-item" : "hdv-btn-forms hdv-btn-default"}
                            onClick={() => this.handleModal("contatos")}>
                            Fechar
                        </button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={modal.acessos} toggle={() => this.handleModal("acessos")} size="lg">
                    <ModalHeader toggle={() => this.handleModal("acessos")}>
                        Acessos
                    </ModalHeader>
                    <ModalBody>
                        <div className="d-flex align-items-end justify-content-between row mb-3">
                            <div className="col-md-12">
                                <label className="required" htmlFor="tipo_acesso">Tipo de Acesso</label>
                                <Select
                                    components={{ Option: IconOption }}
                                    value={this.state.acesso}
                                    options={this.state.tipoAcessos}
                                    onChange={(e) => this.setState({ acesso: e })}
                                />
                                <span id="validacao-tipo_acesso"></span>
                            </div>
                        </div>
                        <ProgressAcessos modulo className="mb-1" />
                    </ModalBody>
                    <ModalFooter>
                        <button
                            className="hdv-btn-forms hdv-btn-blue"
                            onClick={this.addAcessoModulo}>
                            <i className="fa fa-plus"></i>
                            Adicionar Acesso
                        </button>
                        <button
                            className="hdv-btn-forms hdv-btn-default"
                            onClick={() => this.handleModal("acessos")}>
                            Fechar
                        </button>
                    </ModalFooter>
                </Modal>

                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        {this.props.match.params.id ? <h4>Editar Cliente</h4> : <h4>Novo Cliente</h4>}
                        <div className="gray-background">
                            <i className="fa fa-user fa-2x"></i>
                        </div>
                    </span>
                </div>
                <Tabs className="tabs-style" onSelect={(i) => this.handleTab(i)} selectedIndex={this.state.tab}>
                    <TabList>
                        <Tab>Detalhes do cliente</Tab>
                        <Tab disabled={!(this.props.match.params.id)}>Endereços</Tab>
                        <Tab disabled={!(this.props.match.params.id)}>Contatos</Tab>
                        <Tab disabled={!(this.props.match.params.id)}>Acesso à Módulos</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label htmlFor="nome_fantasia" className="required">Nome Fantasia</label>
                            <input type="text" name="nome_fantasia" id="nome_fantasia" value={this.state.detalhes.nome_fantasia} onChange={this.handleChange} />
                            <span id="validacao-nome_fantasia"></span>
                            <label className="required" htmlFor="perfil">Perfil</label>
                            <Select
                                value={selectedOption}
                                onChange={(e) => this.handleChangeSelect(e, 'perfil')}
                                options={this.state.perfis}
                            />
                            <span id="validacao-perfil"></span>
                            <label htmlFor="status" className="required">Status</label>
                            <div className="d-flex">
                                <label className="hdv-fixed">
                                    <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'ativo'} value="ativo" onChange={this.handleChange} /> Ativo
                                </label>
                                <label className="hdv-fixed">
                                    <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'bloqueado'} value="bloqueado" onChange={this.handleChange} /> Bloqueado
                                </label>
                                <label className="hdv-fixed">
                                    <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'cancelado'} value="cancelado" onChange={this.handleChange} /> Cancelado
                                </label>
                            </div>
                            <span id="validacao-status"></span>
                            <details open={this.props.match.params.id} style={{ marginTop: "20px" }} className="custom-summary">
                                <summary>Opcionais</summary>
                                <div style={{ padding: "0px 10px 10px 10px" }} >
                                    <label className='required' htmlFor="email_administrador">E-mail Administrador</label>
                                    <input type="text" name="email_administrador" id="email_administrador" value={this.state.detalhes.email_administrador} onChange={this.handleChange} />
                                    <span id="validacao-email_administrador"></span>
                                    <label htmlFor="razao_social" className="required">Razão Social</label>
                                    <input type="text" name="razao_social" id="razao_social" value={this.state.detalhes.razao_social} onChange={this.handleChange} />
                                    <span id="validacao-razao_social"></span>

                                    <label htmlFor="pessoa_tipo" className="required">Pessoa Tipo</label>
                                    <div className="d-flex">
                                        <label className="hdv-fixed">
                                            <input type="radio" name="pessoa_tipo" id="pessoa_tipo" checked={this.state.detalhes.pessoa_tipo === 'fisica'} value="fisica" onChange={this.handleChange} /> Física
                                        </label>
                                        <label className="hdv-fixed">
                                            <input type="radio" name="pessoa_tipo" id="pessoa_tipo" checked={this.state.detalhes.pessoa_tipo === 'juridica'} value="juridica" onChange={this.handleChange} /> Jurídica
                                        </label>
                                    </div>
                                    <span id="validacao-pessoa_tipo"></span>

                                    <label htmlFor="cpf_cnpj" className="required">CPF / CNPJ</label>
                                    <input type="text" name="cpf_cnpj" id="cpf_cnpj" value={this.state.detalhes.cpf_cnpj} onChange={this.handleChange} />
                                    <span id="validacao-cpf_cnpj"></span>
                                    <label htmlFor="setor" className="required">Setor</label>
                                    <div className="d-flex">
                                        <label className="hdv-fixed">
                                            <input type="radio" name="setor" id="setor" checked={this.state.detalhes.setor === 'publico'} value="publico" onChange={this.handleChange} /> Público
                                        </label>
                                        <label className="hdv-fixed">
                                            <input type="radio" name="setor" id="setor" checked={this.state.detalhes.setor === 'privado'} value="privado" onChange={this.handleChange} /> Privado
                                        </label>
                                    </div>
                                    <span id="validacao-setor"></span>
                                    <br />
                                </div>
                            </details>
                        </form>
                    </TabPanel>
                    <TabPanel>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <h6 className="hdv-subtable-title m-0">Lista de endereços cadastrados</h6>
                            <button className="hdv-btn-forms hdv-btn-green m-0" onClick={() => { this.cancelEditEndereco(); this.handleModal("enderecos", true) }}>
                                Novo Endereço
                            </button>
                        </div>
                        <table className="hdv-subtable afira-table">
                            <thead>
                                <tr>
                                    <td>Descrição</td>
                                    <td>Endereço</td>
                                    <td>Ações</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.createRows("endereco", "enderecos", "descricao", "endereco")}
                            </tbody>
                        </table>
                    </TabPanel>
                    <TabPanel>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <h6 className="hdv-subtable-title m-0">Lista de contatos cadastrados</h6>
                            <button className="hdv-btn-forms hdv-btn-green m-0" onClick={() => { this.cancelEditContato(); this.handleModal("contatos", true) }}>
                                Novo Contato
                            </button>
                        </div>

                        <table className="hdv-subtable afira-table">
                            <thead>
                                <tr>
                                    <td>Descrição</td>
                                    <td>E-mail</td>
                                    <td>Ações</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.createRows("contato", "contatos", "contato", "email")}
                            </tbody>
                        </table>
                    </TabPanel>
                    <TabPanel>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <h6 className="hdv-subtable-title m-0">Lista de acessos</h6>
                            <button className="hdv-btn-forms hdv-btn-green m-0" onClick={() => this.handleModal("acessos")}>
                                Novo Acesso
                            </button>
                        </div>
                        <table className="hdv-subtable afira-table">
                            <thead>
                                <tr>
                                    <td>Descrição</td>
                                    <td>Ativo</td>
                                    <td>Ações</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.modulos.map((modulo, index) => (
                                    <tr key={index}>
                                        <td>{modulo?.acesso_tipo?.descricao}</td>
                                        <td>{modulo.ativo ? "Sim" : "Não"}</td>
                                        <td style={{textAlign: "start"}} className="hdv-subtable-action-td">
                                            <button
                                                style={{padding: "0px", width: "fit-content"}}
                                                onClick={() => this.deleteAcessoModulo(modulo)}
                                                className="hdv-btn-forms hdv-btn-red">
                                                Deletar
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <ButtonsForm
                        route="/cliente"
                        edit={this.props.match.params.edit ? true : false}
                        cooldown={cooldown}
                        buttons={[
                            {
                                method: "post",
                                button: <button className="hdv-btn-forms hdv-btn-green" onClick={() => this.saveAction(false)}>Salvar</button>,
                            }, {
                                method: "post",
                                button: <button style={{ display: this.props.match.params.id ? "none" : "inline-block" }} className="hdv-btn-forms hdv-btn-greenlight" onClick={() => this.saveAction(true)}>Salvar e Cadastrar um Novo</button>
                            }, {
                                method: "patch",
                                button: <button className="hdv-btn-forms hdv-btn-green" onClick={() => this.saveAction(false)}>Salvar</button>,
                            },
                        ]}
                    />
                    <Link to="/cliente">
                        <button className="hdv-btn-forms hdv-btn-yellow">Cancelar</button>
                    </Link>
                </div>
            </div>
        )
    }
}

export default Form
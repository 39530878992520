import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import Select from '../../components/Select'
import { CompactPicker } from 'react-color'
import auth from '../../utils/auth'
import helper from '../../utils/helper'
import cfg from '../../utils/config'
import axios from 'axios'

class Form extends Component {

    state = {
        sistema_cfg: {
            "timezone": "",
            "dominio_personalizado": "",
            "logomarca": "",
            "smtp": "",
            "favicon": "",
            "smtp_porta": "",
            "smtp_usuario": "",
            "smtp_senha": "",
            "endereco": "",
            "telefone": "",
            "email_contato": "",
            "titulo_sistema": "",
            "descricao_sistema": "",
            "descricao_horario_atendimento": "",
        },
        cores_cfg: {
            "degrade_top_primeira_cor": "#0062B1",
            "degrade_top_segunda_cor": "#2f577b",
            "back_logomarca": "#0062B1",
            "back_menu_lateral": "#0062B1",
            "fonte_menu_lateral": "#FFFFFF",
            "fonte_grupo_menu_lateral": "#FFFFFF",
            "login_degrade_primeira_cor_background": "#61abe8",
            "login_degrade_segunda_cor_background": "#2f577b",
            "login_formulario_background": "#ffffff",
            "login_formulario_fonte_cor": "#ffffff",
            "login_formulario_fonte_link": "#58a9ec",
            "login_informacao_background": "#0062B1",
            "login_informacao_fonte_cor": "#aaaaaa",
            "filtro_logo": false
        },
        formerrors: [],
        redirect: false,
        path: "",
        optionSelected: { "timezone": {} },
        timezone: [],
        logomarca: "",
        configuracao_existe: false,
        aviso: { show: false, message: '' },
        cooldown: false,
        modal: { status: false, msg: "" },
        email_teste: "",
        validation_smtp: { smtp: true, smtp_porta: true, smtp_usuario: true, smtp_senha: true, email_teste: true }
    }

    componentDidMount = () => {
        this.getData()
    }

    getData = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/configuracao-sistema/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/configuracao-cores/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/configuracao-timezone/', config_me))

        axios.all(requestArray).then(axios.spread((sistema_cfg, cores_cfg, timezones) => {
            let optionsTimezones = []
            let config = { "timezone": "", "dominio_personalizado": "", "logomarca": "", "smtp": "", "smtp_porta": "", "smtp_usuario": "", "smtp_senha": "" }
            let cores = { "degrade_top_primeira_cor": "#61abe8", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" }
            let config_existe = false
            let logomarca = ""
            let favicon = ""
            let selectedOption = { "timezone": {} }

            if (timezones.status === 200) {
                timezones.data.results.forEach((a) => {
                    optionsTimezones.push({ "value": a.codigo, "label": "(" + a.horas + ") " + a.codigo })
                })
            }

            let label_timezone = timezones.data.results.filter((timezone) => {
                if(sistema_cfg.data.results[0].timezone) {
                    return timezone.codigo === sistema_cfg.data.results[0].timezone
                } else {
                    return timezone.codigo === "America/Sao_Paulo"
                }   
            })
            
            if (sistema_cfg.status === 200) {
                config_existe = true
                config = sistema_cfg.data.results[0]

                for (let key in config) {
                    if (config[key] == null) config[key] = ""
                }

                logomarca = config['logomarca']
                favicon = config['favicon']

                selectedOption['timezone'] = {
                    value: sistema_cfg.data.results[0].timezone,
                    label: `(${label_timezone[0].horas}) ${label_timezone[0].codigo}`
                }
            }

            if (cores_cfg.status === 200) {
                cores = cores_cfg.data.results[0]

                let cssVars = document.querySelector(':root')

                cssVars.style.setProperty('--background-logomarca', cores.back_logomarca)
                cssVars.style.setProperty('--background-menu', cores.back_menu_lateral)
                cssVars.style.setProperty('--fonte_menu_lateral', cores.fonte_menu_lateral)
                cssVars.style.setProperty('--fonte_grupo_menu_lateral', cores.fonte_grupo_menu_lateral)
                cssVars.style.setProperty('--fonte_titulo_abas', cores.degrade_top_primeira_cor)
                cssVars.style.setProperty('--filtro_logo', cores.filtro_logo ? 'brightness(0) invert(1)' : '')
            }

            this.setState({ "timezone": optionsTimezones, favicon, sistema_cfg: config, configuracao_existe: config_existe, logomarca: logomarca, optionSelected: selectedOption, cores_cfg: cores, id: sistema_cfg.data.results[0].id })
        }))
    }

    handleChangeSistema = (e) => {
        let obj = this.state.sistema_cfg
        obj[e.target.id] = e.target.value
        this.setState({ "sistema_cfg": obj })
    }

    handleChangeLogomarca = (e) => {
        let obj = this.state.sistema_cfg

        if (e.target?.files?.[0]) {
            const file = e.target.files[0]
            const sizeInMB = file.size / 100_000

            if (sizeInMB >= 3) {
                helper.dispatchEvent("showAviso", { message: "O aquivo é muito grande. O tamanho da imagem deve ser < 3MB" })
                return
            }

            obj["logomarca"] = e.target.files[0]
            this.setState({ sistema_cfg: obj, logomarca: URL.createObjectURL(e.target.files[0]) })
        }
    }

    handleChangeFavicon = (e) => {
        try {
            let obj = this.state.sistema_cfg

            if (e.target?.files?.[0]) {
                const file = e.target.files[0]
                const sizeInMB = file.size / 100_000

                if (sizeInMB >= 1) {
                    helper.dispatchEvent("showAviso", { message: "O aquivo é muito grande. O tamanho da imagem deve ser < 1MB" })
                    return
                }

                obj["favicon"] = e.target.files[0]
                this.setState({ sistema_cfg: obj, favicon: URL.createObjectURL(e.target.files[0]) })
            }
        } catch (err) {
            console.error(err)
        }
    }

    handleChangeColor = (color, element, variavel) => {
        let cssVars = document.querySelector(':root')

        let obj = this.state.cores_cfg
        obj[element] = color.hex
        cssVars.style.setProperty(variavel, color.hex)

        this.setState({ cores_cfg: obj })
    }

    handleChangeSelect = (selectedOption, field) => {
        let obj = this.state.sistema_cfg
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        this.setState({ "optionSelected": optionObj, "sistema_cfg": obj })
    }

    handleChangeFiltroLogo = (value) => {
        let cssVars = document.querySelector(':root')
        let obj = Object.assign({}, this.state.cores_cfg)

        cssVars.style.setProperty('--filtro_logo', value ? 'brightness(0) invert(1)' : '')
        obj['filtro_logo'] = value

        this.setState({ cores_cfg: obj })
    }

    changeModal = () => {
        this.setState({ modal: { status: false, msg: "" }, cooldown: false })
    }

    validationSmtpTest = (fields) => {
        let validation = Object.assign({}, this.state.validation_smtp)
        let erro = false

        for (let field in fields) {
            if (fields[field] === "") {
                validation[field] = false
                erro = true
            } else {
                validation[field] = true
            }
        }

        this.setState({ validation_smtp: validation })

        return erro
    }

    smtpTest = async (e) => {
        e.preventDefault()
        this.setState({ cooldown: true })

        const sistema_cfg = this.state.sistema_cfg
        let userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()
        let msgRequest = ""

        let fields = {
            smtp: sistema_cfg.smtp,
            smtp_porta: sistema_cfg.smtp_porta,
            smtp_usuario: sistema_cfg.smtp_usuario,
            smtp_senha: sistema_cfg.smtp_senha,
            email_teste: this.state.email_teste
        }

        if (this.validationSmtpTest(fields)) {
            this.setState({ cooldown: false })
            return
        }

        for (let field in fields) {
            bodyFormData.append(field, fields[field])
        }

        await axios({
            method: 'post',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/smtp-teste/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                msgRequest = "Email enviado com sucesso!"
            })
            .catch((error) => {
                msgRequest = error.response.data.msg
            })

        this.setState({ modal: { status: true, msg: msgRequest } })
    }

    saveAction = async () => {
        let { cores_cfg, sistema_cfg } = this.state
        this.setState({ cooldown: true })
        let userInfo = auth.getUserInfo()
        let resource = "configuracao-sistema"
        let verb = this.state.configuracao_existe ? 'PATCH' : 'POST'
        let requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/' + resource + '/' + this.state.id + '/'
        let bodyFormData = new FormData()

        for (let key in sistema_cfg) {
            if (String(sistema_cfg[key]).length != 0) {
                bodyFormData.append(key, sistema_cfg[key])
            }
        }

        if (typeof sistema_cfg.logomarca === "string") {
            bodyFormData.delete("logomarca")
        }

        if (typeof sistema_cfg.favicon === "string") {
            bodyFormData.delete("favicon")
        }

        await axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((response) => {
            if (response.status === 201 || response.status === 200) {
                let requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/configuracao-cores/' + cores_cfg?.id + "/"
                let bodyFormData = new FormData()

                for (let key in this.state.cores_cfg) {
                    bodyFormData.append(key, this.state.cores_cfg[key])
                }

                if (response.data.results?.favicon) {
                    this.setFavicon(response.data.results?.favicon)
                } else {
                    this.setFavicon("/favicon.ico")
                }

                axios({
                    method: verb,
                    url: requestURL,
                    data: bodyFormData,
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
                }).then((res) => {
                    if (cores_cfg.status === 200) {
                        const cores = res.data
                        let cssVars = document.querySelector(':root')

                        cssVars.style.setProperty('--background-logomarca', cores.back_logomarca)
                        cssVars.style.setProperty('--background-menu', cores.back_menu_lateral)
                        cssVars.style.setProperty('--fonte_menu_lateral', cores.fonte_menu_lateral)
                        cssVars.style.setProperty('--fonte_grupo_menu_lateral', cores.fonte_grupo_menu_lateral)
                        cssVars.style.setProperty('--fonte_titulo_abas', cores.degrade_top_primeira_cor)
                        cssVars.style.setProperty('--filtro_logo', cores.filtro_logo ? 'brightness(0) invert(1)' : '')
                    }

                    this.setState({ cores_cfg: res.data })

                    helper.dispatchEvent("showAviso", {
                        message: "Configurações salvas.",
                        callback: () => window.location.reload()
                    })
                })
            }
        }).catch((error) => {
            let erros = Object.entries(error.response.data)
            if (Array.isArray(erros)) {
                erros.forEach((erro) => {
                    if (erro[0] === "dominio_personalizado") {
                        helper.dispatchEvent("showAviso", { message: erro[1][0] })
                    }
                })
            }
        })

        this.setState({ cooldown: false })
    }

    setFavicon = (link = null) => {
        let favicon = document.querySelector('link[rel="icon"]')

        if (favicon !== null) {
            favicon.href = link
        } else {
            favicon = document.createElement("link")
            favicon.rel = "icon"
            favicon.href = link
            document.head.appendChild(favicon)
        }
    }

    render() {
        const timezone = this.state.optionSelected.timezone
        const modal = this.state.modal
        const validacao_smtp = this.state.validation_smtp

        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <div>
                <Modal isOpen={modal.status} toggle={this.changeModal}>
                    <ModalHeader>
                        Aviso
                    </ModalHeader>
                    <ModalBody>
                        <p>{modal.msg}</p>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hdv-btn-group">
                            <button onClick={this.changeModal} className="hdv-btn-forms hdv-btn-red">Fechar</button>
                        </div>
                    </ModalFooter>
                </Modal>
                <div>
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Sistema</h4>
                            <div className="gray-background">
                                <i className="fa fa-desktop fa-2x"></i>
                            </div>
                        </span>
                    </div>
                </div>

                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Sistema</Tab>
                        <Tab>Informações</Tab>
                        <Tab>Cores do Tema</Tab>
                        <Tab>Configuração SMTP</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="hdv-file-picker">
                                        <label>Favicon</label>
                                        <img
                                            className={(this.state.favicon) ? "hdv-logomarca-preview" : "hdv-noshow-item"}
                                            srcSet={this.state.favicon}
                                            style={{ width: "40px", height: "40px" }}
                                            alt="Favicon" />
                                        <br />
                                        <input type="file" name="favicon" id="favicon" accept="image/*,.ico" onChange={this.handleChangeFavicon} />
                                        <label htmlFor="favicon">Selecionar arquivo</label>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <label htmlFor="timezone">Timezone do Sistema</label>
                                    <Select
                                        value={timezone}
                                        onChange={(e) => this.handleChangeSelect(e, 'timezone')}
                                        options={this.state.timezone}
                                    />
                                    <label htmlFor="dominio_personalizado">Domínio Personalizado</label>
                                    <input type="text" name="dominio_personalizado" id="dominio_personalizado" value={this.state.sistema_cfg.dominio_personalizado} onChange={this.handleChangeSistema} />
                                </div>
                            </div>
                        </form>
                    </TabPanel>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="hdv-file-picker">
                                        <label>Logomarca</label>
                                        <img className={(this.state.logomarca) ? "hdv-logomarca-preview" : "hdv-noshow-item"} srcSet={this.state.logomarca} alt="Logomarca Preview" />
                                        <br />
                                        <input type="file" name="logomarca" id="logomarca" onChange={this.handleChangeLogomarca} />
                                        <label htmlFor="logomarca">Selecionar arquivo</label>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <label htmlFor="link_criar_conta">Link para Criar Nova Conta</label>
                                    <input type="text" name="link_criar_conta" id="link_criar_conta" value={this.state.sistema_cfg.link_criar_conta} onChange={this.handleChangeSistema} />

                                    <label htmlFor="titulo_sistema">Título do Sistema</label>
                                    <input type="text" name="titulo_sistema" id="titulo_sistema" value={this.state.sistema_cfg.titulo_sistema} onChange={this.handleChangeSistema} />
                                </div>
                            </div>
                        </form>
                    </TabPanel>
                    <TabPanel>
                        <form className="hdv-form-default">
                            <details style={{ overflow: 'hidden' }} open={true} className='custom-summary'>
                                <summary>Login e Sistema</summary>
                                <div style={{ margin: '0px 0px 20px 0px' }} className="row">
                                    <div className='col-md-4'>
                                        <label>Adicionar filtro branco na logo</label>
                                        <input defaultChecked={this.state.cores_cfg.filtro_logo} type='checkbox' onChange={(e) => { this.handleChangeFiltroLogo(e.target.checked) }} />
                                    </div>
                                </div>
                            </details>

                            <br />

                            <details style={{ overflow: 'hidden' }} open={true} className='custom-summary'>
                                <summary>Sistema</summary>
                                <div style={{ margin: '0px 0px 20px 0px' }} className="row">
                                    <div className="col-md-4">
                                        <label>Background Logomarca</label>
                                        <CompactPicker color={this.state.cores_cfg.back_logomarca} onChange={(color) => this.handleChangeColor(color, 'back_logomarca', '--background-logomarca')} />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Background Menu Lateral</label>
                                        <CompactPicker color={this.state.cores_cfg.back_menu_lateral} onChange={(color) => this.handleChangeColor(color, 'back_menu_lateral', '--background-menu')} />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Fonte do Menu Lateral</label>
                                        <CompactPicker color={this.state.cores_cfg.fonte_menu_lateral} onChange={(color) => this.handleChangeColor(color, 'fonte_menu_lateral', '--fonte_menu_lateral')} />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Fonte do Grupo no Menu Lateral</label>
                                        <CompactPicker color={this.state.cores_cfg.fonte_grupo_menu_lateral} onChange={(color) => this.handleChangeColor(color, 'fonte_grupo_menu_lateral', '--fonte_grupo_menu_lateral')} />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Fonte do Título e Background Abas</label>
                                        <CompactPicker color={this.state.cores_cfg.degrade_top_primeira_cor} onChange={(color) => this.handleChangeColor(color, 'degrade_top_primeira_cor', '--fonte_titulo_abas')} />
                                    </div>
                                </div>
                            </details>

                            <br />

                            <details style={{ overflow: 'hidden' }} open={true} className='custom-summary'>
                                <summary>Login</summary>
                                <div style={{ margin: '0px 0px 20px 0px' }} className="row">
                                    <div className="col-md-4">
                                        <label>Background</label>
                                        <CompactPicker color={this.state.cores_cfg.login_informacao_background} onChange={(color) => this.handleChangeColor(color, 'login_informacao_background')} />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Fonte do botão</label>
                                        <CompactPicker color={this.state.cores_cfg.login_formulario_fonte_cor} onChange={(color) => this.handleChangeColor(color, 'login_formulario_fonte_cor')} />
                                    </div>
                                </div>
                            </details>
                        </form>
                    </TabPanel>
                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-9">
                                    <label htmlFor="smtp">Endereço SMTP</label>
                                    <input type="text" name="smtp" id="smtp" value={this.state.sistema_cfg.smtp} onChange={this.handleChangeSistema} />
                                    <span id="validacao-smtp" className={!validacao_smtp.smtp ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="smtp_porta">Porta SMTP</label>
                                    <input type="text" name="smtp_porta" id="smtp_porta" value={this.state.sistema_cfg.smtp_porta ?? "null"} onChange={this.handleChangeSistema} />
                                    <span id="validacao-smtp_porta" className={!validacao_smtp.smtp_porta ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="smtp_usuario">Usuário</label>
                                    <input type="text" name="smtp_usuario" id="smtp_usuario" value={this.state.sistema_cfg.smtp_usuario} onChange={this.handleChangeSistema} autoComplete="new-password" />
                                    <span id="validacao-smtp_usuario" className={!validacao_smtp.smtp_usuario ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="smtp_senha">Senha</label>
                                    <input className="hdv-form-system-password-field" type="password" name="smtp_senha" id="smtp_senha" value={this.state.sistema_cfg.smtp_senha} onChange={this.handleChangeSistema} autoComplete="new-password" />
                                    <span id="validacao-smtp_senha" className={!validacao_smtp.smtp_senha ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                </div>
                            </div>

                            <br />

                            <details style={{ overflow: 'hidden' }} open={true} className='custom-summary'>
                                <summary>Teste de email (opcional)</summary>
                                <div style={{ display: "flex", alignItems: "end", margin: "20px 0px" }}>
                                    <div className="col-md-6">
                                        <div>
                                            <label htmlFor="smtp_senha">Email para receber o teste</label>
                                            <input
                                                className="hdv-form-system-password-field"
                                                onChange={(e) => {
                                                    this.setState({ email_teste: e.target.value })
                                                }}
                                            />
                                            <span id="validacao-email_teste" className={!validacao_smtp.email_teste ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <button onClick={(e) => this.smtpTest(e)} className="hdv-btn-forms hdv-btn-blue" disabled={this.state.cooldown}>Testar</button>
                                    </div>
                                </div>
                            </details>
                        </form>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <button
                        onClick={this.saveAction}
                        className="hdv-btn-forms hdv-btn-green"
                        disabled={this.state.cooldown}>
                        Salvar
                    </button>
                </div>
            </div>
        )
    }
}

export default Form